/* import __COLOCATED_TEMPLATE__ from './views.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { SearchableType } from 'embercom/models/data/inbox/searchable-types';
import type Inbox from 'embercom/objects/inbox/inboxes/inbox';
import type ViewSummary from 'embercom/objects/inbox/view-summary';
import type Session from 'embercom/services/session';
import storage from 'embercom/vendor/intercom/storage';
import { type InboxFolder } from 'embercom/objects/inbox/inbox-folder';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  pinnedInboxes: Inbox[];
  addPin: (inbox: Inbox) => void;
  removePin: (inbox: Inbox) => void;
  reorderInboxes: (inboxes: Inbox[], draggedInbox: Inbox) => void;
  onCreateView: () => void;
  onEditView: (row: ViewSummary, hidePopover: Function | null) => void;
  isHoverable: boolean;
  customFolders?: InboxFolder[];
  onCreateCustomFolder: () => void;
  togglePin: () => void;
}

interface Signature {
  Args: Args;
}

export default class Views extends Component<Signature> {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @tracked openSectionId: string | undefined;

  @tracked isHovering = false;

  entityType = SearchableType.View;
  inboxCategory = InboxCategory.View;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.updateSectionId();
  }

  @action
  removePinHandler(inbox: Inbox) {
    this.args.removePin(inbox);
    this.updateSectionId();
  }

  @action
  updateSectionId() {
    let label = `inbox.inbox-list.views-accordion-${this.session.workspace.id}`;
    if (this.args.pinnedInboxes.length === 0) {
      this.openSectionId = undefined;
    } else if (storage.get(label) === null || storage.get(label) === true) {
      this.openSectionId = 'section_a';
    } else {
      this.openSectionId = undefined;
    }
  }

  get isDisabled() {
    return this.args.pinnedInboxes.length === 0;
  }

  @action trackOpenPopover() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'inbox_list_dropdown',
      inbox_type: InboxType.View,
    });
  }

  @action trackInboxHover() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'hover',
      object: 'inbox',
      inbox_type: InboxType.View,
    });
  }
  @action onOpenSectionChange(sectionId: string) {
    if (sectionId === 'section_a') {
      this.openSectionId = sectionId;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'opened',
        place: 'inbox',
        object: 'inbox_list_dropdown_views',
        section: 'inbox',
      });
      storage.set(`inbox.inbox-list.views-accordion-${this.session.workspace.id}`, true);
    } else {
      this.openSectionId = undefined;
      storage.set(`inbox.inbox-list.views-accordion-${this.session.workspace.id}`, false);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::Folders::Default::Views': typeof Views;
  }
}
