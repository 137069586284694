/* import __COLOCATED_TEMPLATE__ from './select-language.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Args {
  locale: string;
  onClose: () => unknown;
  onSelect: () => unknown;
  onNext: () => unknown;
}

interface Signature {
  Args: Args;
}

export default class Inbox2OnboardingSelectLanguage extends Component<Signature> {
  @service intl!: IntlService;

  get options() {
    return this.intl.supportedLanguages.map(function ({ locale: id, language: label }) {
      return { id, label };
    });
  }

  get selectedLanguage() {
    if (this.args.locale === 'en') {
      return this.options.find((option) => option.id === 'en-US');
    }

    return this.options.find((option) => option.id.toLowerCase() === this.args.locale);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::SelectLanguage': typeof Inbox2OnboardingSelectLanguage;
    'inbox2/onboarding/select-language': typeof Inbox2OnboardingSelectLanguage;
  }
}
