/* import __COLOCATED_TEMPLATE__ from './sort-by-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type SortDirection, type SortField } from 'embercom/services/inbox-api';

interface Args {
  item: {
    text: string;
    icon: InterfaceIconName;
    value: SortField;
  };
  componentAttrs: {
    isSelected: boolean;
    direction: SortDirection;
  };
}

interface Signature {
  Args: Args;
}

const ConversationListSortByItemComponent = templateOnlyComponent<Signature>();
export default ConversationListSortByItemComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationList::SortByItem': typeof ConversationListSortByItemComponent;
    'inbox2/left-nav/conversation-list/sort-by-item': typeof ConversationListSortByItemComponent;
  }
}
