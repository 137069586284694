/* import __COLOCATED_TEMPLATE__ from './start-linked-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState, NewConversation } from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import type EmojiService from 'embercom/services/emoji-service';
import { Channel } from 'embercom/models/data/inbox/channels';
import { action } from '@ember/object';
import {
  type ComposerPaneType,
  ObjectTypeForReplyChannel,
  ReplyChannelType,
} from 'embercom/objects/inbox/composer-pane';
import { type BlockList } from '@intercom/interblocks.ts';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import { type ReplyDataType } from 'embercom/services/inbox-api';
import { type SendAndCloseFn, type SendAndSnoozeFn } from 'embercom/objects/inbox/types/composer';
import { taskFor } from 'ember-concurrency-ts';
import { type SendOptsType } from 'embercom/components/inbox2/new-conversation';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import { task } from 'ember-concurrency-decorators';
import Chat, {
  type SenderSummary as ChatSenderSummary,
} from 'embercom/objects/inbox/renderable/chat';
import RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { toggleMessengerVisibility } from 'embercom/lib/intercom-widget-helper';
import { registerDestructor } from '@ember/destroyable';
import UnassignedTeamSummary from 'embercom/objects/inbox/unassigned-team-summary';
import { DurationType } from 'embercom/objects/inbox/duration';
import { type NewConversationWireFormat } from 'embercom/lib/inbox2/types';
import { ChatChannel, EmailChannel } from 'embercom/objects/inbox/composer-pane';
import { type ReplyChannel } from 'embercom/objects/inbox/composer-pane';
import { type OutboundFieldVisibilitySettings } from 'embercom/components/inbox2/composer/outbound-fields';

interface Args {
  isCollapsed: boolean;
  closeSidebar: () => void;
  goBack: () => void;
}

interface Signature {
  Args: Args;
}

export default class StartLinkedConversation extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare emojiService: EmojiService;

  readonly linkedConversation: NewConversation = new NewConversation();

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.setupLinkedConversation();
    this.setupMessengerVisibility();
  }

  get userName(): string {
    return this.linkedConversation.userSummary?.name || '';
  }

  get sender() {
    return this.linkedConversation?.sender;
  }

  get replyChannel(): Channel {
    return Channel.Desktop;
  }

  get isCreatingLinkedConversation() {
    return taskFor(this.createLinkedConversation).isRunning;
  }

  get supportedReplyChannels(): ReplyChannel[] {
    return [ChatChannel, EmailChannel];
  }

  get outboundFieldVisibilitySettings(): OutboundFieldVisibilitySettings {
    return {
      hideSenderField: true,
      hideRecipientsField: true,
      hideSubjectField: false,
    };
  }

  @action
  async onSend(
    _type: ComposerPaneType,
    blocks: BlockList,
    macroActions: Array<MacroAction>,
    paneChannel: ReplyChannelType,
    data: ReplyDataType,
  ) {
    await this.send(blocks, paneChannel, macroActions, { data });
  }

  onSendAndClose: SendAndCloseFn = async (blocks, macroActions, paneChannel, data) => {
    if (paneChannel === null || paneChannel === undefined) {
      throw new Error('paneChannel must be set to create a linked conversation');
    }

    await this.send(blocks, paneChannel, macroActions, { state: ConversationState.Closed, data });
  };

  onSendAndSnooze: SendAndSnoozeFn = async (blocks, macroActions, duration, paneChannel, data) => {
    if (paneChannel === null || paneChannel === undefined) {
      throw new Error('paneChannel must be set to create linked conversation');
    }

    await this.send(blocks, paneChannel, macroActions, { duration, data });
  };

  async send(
    blocks: BlockList,
    paneChannel: ReplyChannelType,
    macroActions: Array<MacroAction>,
    opts?: SendOptsType,
  ) {
    let data = this.buildConversationData(blocks, paneChannel, opts);
    await taskFor(this.createLinkedConversation).perform(data, macroActions);
  }

  @task({ drop: true })
  *createLinkedConversation(data: NewConversationWireFormat, macroActions: Array<MacroAction>) {
    this.linkedConversation.addRenderablePart(this.createRenderablePart(data));
    let linkedConversation = (yield this.inboxState.createNewConversation(data, macroActions)) as {
      id: number;
    };

    this.inboxState.showConversationList();
    this.inboxSidebarService.toggleIsViewingStartLinkedConversation();
    this.inboxSidebarService.previewConversation(linkedConversation as Conversation);
  }

  @action setTitle(title: string) {
    this.linkedConversation.title = title;
  }

  private setupLinkedConversation() {
    let activeConversation = this.inboxState.activeConversation as Conversation;
    this.linkedConversation.adminAssignee = this.session.teammate;
    this.linkedConversation.teamAssignee = UnassignedTeamSummary;
    this.linkedConversation.sender = this.session.teammate;
    this.linkedConversation.participants = [...activeConversation.participantSummaries];
    this.linkedConversation.title = activeConversation.ticketTitle;
  }

  private setupMessengerVisibility() {
    toggleMessengerVisibility({ hide: true });

    registerDestructor(this, () => {
      toggleMessengerVisibility({ hide: false });
    });
  }

  private buildConversationData(
    blocks: BlockList,
    paneChannel: ReplyChannelType,
    opts?: SendOptsType,
  ) {
    let activeConversation = this.inboxState.activeConversation as Conversation;
    let data: NewConversationWireFormat = {
      app_id: this.session.workspace.id,
      type: ObjectTypeForReplyChannel[paneChannel],
      blocks,
      subject: this.linkedConversation.title,
      admin_assignee_id: this.linkedConversation.adminAssignee?.id,
      team_assignee_id: this.linkedConversation.teamAssignee?.id,
      sender_id: this.sender?.id,
      sender_type: senderTypes.admin,
    };

    if (opts) {
      if ('state' in opts) {
        data.state = this.getConversationState(opts.state as ConversationState);
      }

      if (opts.duration) {
        data.snoozed_until = opts.duration.type;
        data.author_timezone = opts.duration.timezone;

        if (opts.duration.type === DurationType.CustomTime) {
          data.custom_snoozed_until_time = opts.duration.time;
        }
      }

      if ('data' in opts && paneChannel === ReplyChannelType.Whatsapp) {
        data.data = {
          whatsapp: {
            integration_id: this.sender?.id,
            template_name: opts.data?.whatsapp_template_name,
            template_language: opts.data?.whatsapp_template_language,
          },
        };
      }
    }

    let new_users: { email_address?: string }[] = [];
    let user_ids: string[] = [];

    this.linkedConversation.participantSummaries.forEach((user) =>
      user.isNewUser ? new_users.push({ email_address: user.email }) : user_ids.push(user.id),
    );

    data.new_users = new_users;
    data.user_ids = user_ids;
    data.ticket_id_to_link = activeConversation.isTicket ? activeConversation.id : undefined;

    return data;
  }

  private getConversationState(state: ConversationState) {
    return {
      [ConversationState.Open]: 0,
      [ConversationState.Closed]: 1,
      [ConversationState.Snoozed]: 2,
    }[state];
  }

  private createRenderablePart(data: Record<string, unknown>) {
    let renderableData = new Chat(
      data.blocks as object[],
      this.sender as ChatSenderSummary,
      OutboundInitialPartSenderType.Admin,
    );

    return new RenderablePart(-1, RenderableType.Chat, renderableData, new Date(), 0, 0);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::StartLinkedConversation': typeof StartLinkedConversation;
    'inbox2/start-linked-conversation': typeof StartLinkedConversation;
  }
}
