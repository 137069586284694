/* import __COLOCATED_TEMPLATE__ from './auto-translation-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';
import { action } from '@ember/object';

interface Args {
  item: {
    componentAttrs: {};
  };
}

interface Signature {
  Args: Args;
}

export default class AutoTranslationSwitcher extends Component<Signature> {
  @service declare session: Session;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;

  get value() {
    return this.conversationTranslationSettings.autoTranslationEnabled;
  }

  @action
  toggleAutoTranslation() {
    this.conversationTranslationSettings.toggleTranslation();
  }

  @action
  toggleAutoTranslationNoop() {
    // noop since div covers the whole component and trigger toggle
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::AutoTranslationSwitcher': typeof AutoTranslationSwitcher;
    'inbox2/user-menu/auto-translation-switcher': typeof AutoTranslationSwitcher;
  }
}
