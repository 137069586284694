/* import __COLOCATED_TEMPLATE__ from './user-menu.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type Router from '@ember/routing/router-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import { indexBy } from 'underscore';
import { type WorkspaceForList } from '../../objects/workspace';
import { type AwayReason } from 'embercom/services/admin-away-service';
import type AdminAwayService from 'embercom/services/admin-away-service';
interface Args {
  showThemeSwitcher: boolean;
  isChannelSwitcherActive: boolean;
  showSimplifiedGuideLibraryNav: boolean;
  onPopoverShow?: () => void;
  onPopoverHide?: () => void;
  iaNavBarExpanded?: boolean;
  disabled?: boolean;
}

interface Signature {
  Args: Args;
}

export default class UserMenu extends Component<Signature> {
  @service declare session: Session;
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare adminAwayService: AdminAwayService;
  @service declare notificationsService: any;
  // DO NOT ACCESS DATA USING THIS SERVICE! Refer to https://github.com/intercom/embercom/pull/89623 for context on why this is being used here
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service declare modelDataCacheService: any;
  @tracked workspacesList: Array<WorkspaceForList> = [];

  @tracked isProfileMenuOpen = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchWorkspaces).perform();
  }

  @action onOpen() {
    this.isProfileMenuOpen = true;
    this.args.onPopoverShow?.();
  }

  @action onClose() {
    this.isProfileMenuOpen = false;
    this.args.onPopoverHide?.();
  }

  @task *fetchWorkspaces() {
    yield this.session.getWorkspaces();

    let productionWorkspaces = this.session.workspacesList.filter((w) => !w.parent_app_id);
    let testWorkspaces = this.session.workspacesList.filter((w) => w.parent_app_id);
    let indexedTestWorkspaces = indexBy(testWorkspaces, (w) => w.parent_app_id);

    this.workspacesList = productionWorkspaces
      .sortBy('name')
      .flatMap((w) => [w, indexedTestWorkspaces[w.id]])
      .compact();
  }

  @action toggleAwayMode(event: Event) {
    event.preventDefault();
    this.adminAwayService.toggleAwayMode();
  }

  @action toggleReassignMode(event: Event) {
    event.preventDefault();
    this.adminAwayService.toggleReassignConversations();
  }

  @action setAwayReason(reason: AwayReason) {
    this.adminAwayService.setAwayReason(reason);
  }

  get isActive() {
    return !this.adminAwayService.awayModeEnabled;
  }

  get isAway() {
    return this.adminAwayService.awayModeEnabled;
  }

  get isChannelSwitcherActive() {
    return this.args.isChannelSwitcherActive;
  }

  get translationEnabled() {
    return this.session.workspace.isFeatureEnabled('realtime-translation');
  }

  get isIntercomradesDomain() {
    return document.domain.includes('intercomrade');
  }

  get isInboxPage() {
    return this.router.currentRouteName.startsWith('inbox.workspace');
  }

  get modifyProductItem() {
    if (!this.isIntercomradesDomain || this.isInboxPage) {
      return [];
    }

    return [
      {
        items: [
          {
            component: 'inbox2/user-menu/modify-product-link',
          },
        ],
      },
    ];
  }

  get groupList() {
    if (this.args.showSimplifiedGuideLibraryNav) {
      return [
        {
          items: this.avatarItems,
        },
        {
          items: [
            {
              model: this.session.teammate,
              component: 'inbox2/user-menu/workspaces-switcher',
              componentAttrs: {
                workspacesList: this.workspacesList,
              },
            },
          ],
        },
        {
          items: this.logoutLinkItems,
        },
      ];
    }

    return [
      {
        items: this.avatarItems,
      },
      ...(this.translationEnabled
        ? [
            {
              items: [
                {
                  model: this.session.workspace,
                  component: 'inbox2/user-menu/auto-translation-switcher',
                  componentAttrs: {},
                },
              ],
            },
          ]
        : []),
      ...(this.isChannelSwitcherActive
        ? [
            {
              items: [
                {
                  model: this.session.teammate,
                  component: 'inbox2/user-menu/channel-switcher',
                  componentAttrs: {
                    defaultAvailableChannel: this.adminAwayService.channelAvailability,
                  },
                },
              ],
            },
          ]
        : []),
      ...(this.args.showThemeSwitcher
        ? [
            {
              items: [
                {
                  model: this.session.workspace,
                  component: 'inbox2/user-menu/theme-switcher',
                  componentAttrs: {},
                },
              ],
            },
          ]
        : []),
      {
        items: [
          {
            model: this.session.workspace,
            component: 'inbox2/user-menu/locale-switcher',
            componentAttrs: {},
          },
        ],
      },
      {
        items: [
          {
            model: this.session.teammate,
            component: 'inbox2/user-menu/workspaces-switcher',
            componentAttrs: {
              workspacesList: this.workspacesList,
            },
          },
        ],
      },
      ...this.modifyProductItem,
      {
        items: this.menuLinkItems,
      },
      {
        items: this.logoutLinkItems,
      },
    ];
  }

  get menuLinkItems() {
    let helpLinkComponent = 'inbox2/user-menu/consistent-nav/help-link';

    return [
      {
        text: this.intl.t('inbox.user-menu.help-center'),
        value: 'https://www.intercom.com/help/',
        component: helpLinkComponent,
        target: '_blank',
      },
      {
        text: this.intl.t('inbox.user-menu.intercom-community-forum'),
        value: 'https://community.intercom.com/',
        component: helpLinkComponent,
        target: '_blank',
      },
      {
        text: this.intl.t('inbox.user-menu.status'),
        value: 'https://www.intercomstatus.com',
        component: helpLinkComponent,
        target: '_blank',
      },
      {
        text: this.intl.t('inbox.user-menu.terms'),
        value: 'https://www.intercom.com/terms-and-policies',
        component: helpLinkComponent,
        target: '_blank',
      },
    ];
  }

  get avatarItems() {
    let avatarComponent = 'inbox2/user-menu/consistent-nav/avatar';

    if (this.args.showSimplifiedGuideLibraryNav) {
      return [
        {
          text: this.session.teammate.name,
          value: this.session.teammate,
          isActive: this.isActive,
          app: this.session.workspace.id,
          component: avatarComponent,
          componentAttrs: {
            showSimplifiedGuideLibraryNav: this.args.showSimplifiedGuideLibraryNav,
          },
        },
      ];
    }

    return this.session.showLightInbox
      ? [
          {
            text: this.session.teammate.name,
            value: this.session.teammate,
            isActive: this.isActive,
            app: this.session.workspace.id,
            component: avatarComponent,
          },
        ]
      : [
          {
            text: this.session.teammate.name,
            value: this.session.teammate,
            isActive: this.isActive,
            app: this.session.workspace.id,
            component: avatarComponent,
            aliasEnabled:
              this.session.workspace.isFeatureEnabled('team-messenger-admin-alias') &&
              this.session.teammate?.aliasEnabled,
          },
          {
            text: this.session.teammate.name,
            value: this.adminAwayService.awayModeEnabled,
            component: 'inbox2/user-menu/set-status',
            toggleAwayMode: this.toggleAwayMode,
          },
          {
            text: this.session.teammate.name,
            value: this.adminAwayService.reassignConversations,
            component: 'inbox2/user-menu/reassign',
            toggleReassignMode: this.toggleReassignMode,
            awayModeTooltip: '',
            hidden: !this.adminAwayService.awayModeEnabled,
          },
          {
            text: this.session.teammate.name,
            value: this.adminAwayService.awayStatusReason,
            component: 'inbox2/user-menu/set-away-reason',
            setAwayReason: this.setAwayReason,
            hidden: !this.adminAwayService.awayModeEnabled,
          },
        ].filter((el) => !el.hidden);
  }

  get logoutLinkItems() {
    let helpLinkComponent = 'inbox2/user-menu/consistent-nav/help-link';

    return [
      {
        text: this.intl.t('inbox.user-menu.logout'),
        value: '/admins/sign_out',
        component: helpLinkComponent,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu': typeof UserMenu;
    'inbox2/user-menu': typeof UserMenu;
  }
}
