/* import __COLOCATED_TEMPLATE__ from './locale-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export type ValidLocales = 'en-US' | 'pt-BR' | 'fr-FR' | 'de-DE';

interface Args {
  item: {
    componentAttrs: {};
  };
}

interface Signature {
  Args: Args;
}

export default class LocaleSwitcherPopover extends Component<Signature> {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  @action async changeLocale(newLocale: ValidLocales) {
    this.session.changeLanguage(newLocale);
  }

  get selectedLocale() {
    return this.session.locale;
  }

  get selectedLocaleName() {
    let locale = this.localeList.find(
      (locale) => locale.value.toLowerCase() === this.selectedLocale,
    );
    return locale?.name;
  }

  get currentAdmin() {
    return this.session.teammate;
  }

  get localeList() {
    return this.intl.supportedLanguages.map(function ({ locale: value, language: name }) {
      return { value, name };
    });
  }

  // This function is a Popper.js modifier (https://popper.js.org/docs/v2/modifiers/)
  // that keeps the dimensions of the app switcher popover the same as those of
  // the primary nav menu popover. This behavior is a design requirement from
  // the "early 2018" brand refresh.
  matchPopoverDimensionsToMenuPopover(popperModifierData: any) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::UserMenu::LocaleSwitcher': typeof LocaleSwitcherPopover;
    'inbox2/user-menu/locale-switcher': typeof LocaleSwitcherPopover;
  }
}
