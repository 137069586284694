/* import __COLOCATED_TEMPLATE__ from './new-side-conversation-panel.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Session from 'embercom/services/session';
import { type MessageSender } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import { NewConversation } from 'embercom/objects/inbox/conversation';
import type InboxState from 'embercom/services/inbox-state';
import { ComposerLocation } from 'embercom/services/inbox-state';
import type EmojiService from 'embercom/services/emoji-service';
import { Channel } from 'embercom/models/data/inbox/channels';
import { action } from '@ember/object';
import { type ComposerPaneType, type ReplyChannelType } from 'embercom/objects/inbox/composer-pane';
import { type BlockList } from '@intercom/interblocks.ts';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import { type ReplyDataType } from 'embercom/services/inbox-api';
import { taskFor } from 'ember-concurrency-ts';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import { task } from 'ember-concurrency-decorators';
import Email, {
  type SenderSummary as EmailSenderSummary,
} from 'embercom/objects/inbox/renderable/email';
import RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { toggleMessengerVisibility } from 'embercom/lib/intercom-widget-helper';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type InboxApi from 'embercom/services/inbox-api';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type CommandKService from 'embercom/services/command-k';
import { DisplayContext } from 'embercom/services/command-k';
import type AdminPermissions from 'embercom/services/admin-permissions';
import storage from 'embercom/vendor/intercom/storage';
import { EscKeyPriority } from 'embercom/lib/inbox2/types';
import { type Recipients } from 'embercom/lib/composer/recipients';

interface Args {
  parentConversation: Conversation;
  isCollapsed: boolean;
  closeSidebar: () => void;
  goBack: () => void;
}

interface Signature {
  Args: Args;
}

export default class NewSideConversationPanel extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxState: InboxState;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare emojiService: EmojiService;
  @service declare inboxApi: InboxApi;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare commandK: CommandKService;
  @service declare adminPermissions: AdminPermissions;

  @tracked recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[] = [];
  @tracked sender: MessageSender | undefined;
  @tracked sideConversationId: number | undefined;
  readonly sideConversation: NewConversation = new NewConversation();
  successfullyCreated: boolean;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.setSender();
    this.setupMessengerVisibility();
    this.inboxState.setActiveComposer(ComposerLocation.ConversationPreviewPanel);
    this.commandK.setActivationContext(DisplayContext.SideConversation);
    this.successfullyCreated = false;
  }

  willDestroy() {
    if (!this.successfullyCreated) {
      this.inboxState.setActiveComposer(ComposerLocation.ConversationPage);
      this.commandK.resetActivationContext();
      toggleMessengerVisibility({ hide: false });
    }
    super.willDestroy();
  }

  get replyChannel(): Channel {
    return Channel.Email;
  }

  get isCreatingSideConversation() {
    return taskFor(this.createNewSideConversation).isRunning;
  }

  get recipients() {
    return this.sideConversation.participants;
  }

  get location() {
    return ComposerLocation.ConversationPreviewPanel;
  }

  get hasRecipientError() {
    return this.recipientsWithErrors.any(({ recipientError }) => recipientError !== undefined);
  }

  get savedSender() {
    return storage.get(this.sideConversationSenderStorageKey);
  }

  get sideConversationSenderStorageKey() {
    return `side-conversation-sender-${this.session.workspace.id}`;
  }

  get canCreateConversation(): boolean {
    return this.recipientSelected && !!this.sender && !this.hasRecipientError;
  }

  get recipientSelected(): boolean {
    if (!this.sideConversation.userSummary) {
      return false;
    }

    let { id, email } = this.sideConversation.userSummary;
    return !!id || !!email;
  }

  @action setRecipientErrors(
    recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[],
  ) {
    this.recipientsWithErrors = recipientsWithErrors;
  }

  @action onSenderChange(sender: MessageSender) {
    storage.set(this.sideConversationSenderStorageKey, sender);
    this.sender = sender;
  }

  @action updateActivationContext() {
    if (this.commandK.currentActivationContext !== DisplayContext.SideConversation) {
      this.commandK.setActivationContext(DisplayContext.SideConversation);
    }
  }

  @action setTitle(title: string) {
    this.sideConversation.title = title;
  }

  @action onRecipientChange(recipients?: UserSummary[]) {
    if (recipients) {
      this.sideConversation.participants = recipients;
    } else {
      this.sideConversation.participants = [];
    }
  }

  @action onRecipientManagerChange(recipients: Recipients) {
    this.sideConversation.recipients = recipients.serialize();
  }

  @action async onSend(
    _type: ComposerPaneType,
    blocks: BlockList,
    macroActions: Array<MacroAction>,
    _paneChannel: ReplyChannelType,
    _data: ReplyDataType,
  ) {
    let data = this.buildNewConversationData(blocks);
    let result = false;
    result = await taskFor(this.createNewSideConversation).perform(data, macroActions);

    if (result) {
      return {};
    } else {
      return {
        errors: [true],
      };
    }
  }

  @task({ drop: true })
  *createNewSideConversation(
    data: Record<string, unknown>,
    macroActions: Array<MacroAction>,
  ): Generator<unknown, boolean, any> {
    try {
      // This is so the part is rendered in the UI as a preview
      this.sideConversation.addRenderablePart(this.createRenderablePart(data));

      let sideConversationCreated = yield this.inboxState.createNewSideConversation(
        data,
        macroActions,
      );

      this.sideConversationId = sideConversationCreated.id;
      return true;

      // TODO: toggle side conversation view to show side conversation to the user, see this.inboxSidebarService.previewConversation() for inspiration
    } catch (e) {
      this.snackbar.notifyError(yield this.getErrorMessage(e));
      return false;
    }
  }

  private async getErrorMessage(e: any): Promise<string> {
    try {
      let errors: any = await e.response.json();
      return (
        errors.errors ||
        errors.message.errors ||
        this.intl.t('inbox.linked-tickets.new-side-conversation-panel.error')
      );
    } catch (error) {
      return this.intl.t('inbox.linked-tickets.new-side-conversation-panel.error');
    }
  }

  private reloadSideConversationThread() {
    this.inboxSidebarService.closeNewSideConversationPanel();
    this.successfullyCreated = true;

    this.inboxSidebarService.previewSideConversation(
      this.sideConversationId!,
      this.args.parentConversation.id,
      true,
    );
  }

  @action onSendComplete() {
    this.reloadSideConversationThread();
  }

  private setupMessengerVisibility() {
    toggleMessengerVisibility({ hide: true });
  }

  private buildNewConversationData(blocks: BlockList) {
    let newUsers: Record<string, string | undefined>[] = [];
    let userIds: string[] = [];
    this.sideConversation.participantSummaries.forEach((user) =>
      user.isNewUser ? newUsers.push({ email_address: user.email }) : userIds.push(user.id),
    );

    let data = {
      app_id: this.session.workspace.id,
      // Assumes compose is using email as the default
      type: objectTypes.email,
      blocks,
      subject: this.sideConversation.title,
      sender_id: this.sender?.id,
      sender_type: this.Inbox1SenderType,
      new_users: newUsers,
      user_ids: userIds,
      parent_conversation_id: this.args.parentConversation.id,
      recipients: this.sideConversation.recipients,
    } as Record<string, unknown>;

    return data;
  }

  private createRenderablePart(data: Record<string, unknown>) {
    let renderableData = new Email(
      data.blocks as object[],
      this.sender as EmailSenderSummary,
      this.senderType,
      this.sideConversation.title,
    );

    return new RenderablePart(-1, RenderableType.Email, renderableData, new Date(), 0, 0);
  }

  get senderType() {
    return this.sender?.id === this.session.teammate.id
      ? OutboundInitialPartSenderType.Admin
      : OutboundInitialPartSenderType.CustomEmailAddress;
  }

  get Inbox1SenderType() {
    return this.senderType === OutboundInitialPartSenderType.Admin
      ? senderTypes.admin
      : senderTypes.customEmailAddress;
  }

  async setSender() {
    if (this.savedSender && (await this.senderExists(this.savedSender))) {
      this.sender = this.savedSender;
      return;
    }

    this.sender = this.session.teammate;
  }

  async senderExists(sender: MessageSender | undefined) {
    if (sender?.id === this.session.teammate.id) {
      return true;
    }

    let customEmailAddresses = await this.inboxApi.fetchSenderEmailAddresses();
    if (customEmailAddresses.any((address) => address.id === sender?.id)) {
      return true;
    }

    return false;
  }

  get useDefaultSenderEmailAddress() {
    return this.adminPermissions.canSendFromCustomAddresses && !!this.defaultSenderEmailAddress;
  }

  get defaultSenderEmailAddress() {
    return this.session.workspace.emailSenderSettings?.adminReplyDefaultAddress;
  }

  get escPriority() {
    return EscKeyPriority.sidebarPreview;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewSideConversationPanel': typeof NewSideConversationPanel;
    'inbox2/new-side-conversation-panel': typeof NewSideConversationPanel;
  }
}
