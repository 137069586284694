/* import __COLOCATED_TEMPLATE__ from './user-search-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { later, schedule } from '@ember/runloop';
import { taskFor } from 'ember-concurrency-ts';
import UserSummary from 'embercom/objects/inbox/user-summary';

import {
  DropdownNoMatchForType,
  DropdownSuggestion,
  DropdownUnselectableSuggestion,
} from 'embercom/objects/inbox/search/dropdown-items';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import { useResource } from 'ember-resources';
import NavigableSelection from 'embercom/components/inbox2/common/navigable-selection-resource';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';
import type ClipboardService from 'embercom/services/clipboard-service';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';

// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { Channel } from 'embercom/models/data/inbox/channels';

interface Args {
  onUserSelect: (user?: UserSummary) => void;
  disabled?: boolean;
  limit?: number;
  placeholder?: string;
  currentUsers: UserSummary[];
  channel?: Channel;
  isCreatingConversation?: boolean;
}

type SearchListItemType =
  | DropdownSuggestion
  | DropdownNoMatchForType
  | DropdownUnselectableSuggestion;

const DEFAULT_QUERY_LIMIT = 10;
export default class UserSearchDropdown extends Component<Args> {
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @service declare router: RouterService;
  @service declare clipboardService: ClipboardService;
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare intl: IntlService;
  @service declare session: Session;

  @tracked searchList: SearchListItemType[] = [];
  @tracked inputText = '';
  @tracked searchHasNoResults = false;
  @tracked isInputFocused = false;
  @ref('user-input') declare inputElement: HTMLElement;

  popover?: { hide: () => void; show: () => void };
  clipboard?: any;

  readonly list = useResource(this, NavigableSelection, () => ({
    items: this.searchList,
    equalityComparatorFn: this.equalityComparatorFn,
  }));

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get inputLooksLikeEmail() {
    return this.inputText?.match(/.+\@.+\..+/);
  }

  get isSearchingUsers() {
    return taskFor(this.searchUsers).isRunning;
  }

  @action setPopover(popover: { hide: () => void; show: () => void }) {
    this.popover = popover;
  }

  @action async onPopoverHide() {
    // Make sure we wait for any searches that are running to finish.
    await taskFor(this.searchUsers).last;

    // If we have an input at this point, try to create a user with it
    let input = this.inputText?.trim() ?? '';
    let itemMatchingInput = this.searchList.find(
      (item: DropdownSuggestion) => item.suggestion?.email === input.toLocaleLowerCase(),
    );

    if (itemMatchingInput) {
      this.addUser((itemMatchingInput as DropdownSuggestion).suggestion);
    } else if (input) {
      this.createNewUser(this.inputText);
    }
  }

  @action
  createNewUser(email: string) {
    let user = new UserSummary('', email);
    this.addUser(user);
  }

  @action
  addUser(user: UserSummary) {
    this.inputText = '';
    this.args.onUserSelect(user);
    this.popover?.hide();
    this.searchList = [];
  }

  @task({ restartable: true })
  *searchUsers(query: string) {
    this.searchList = [];
    let selectedUsers = this.args.currentUsers;

    let users: UserSummary[] = yield this.inboxSearchSuggestionsService.getUserSuggestions(
      query,
      this.args.limit || DEFAULT_QUERY_LIMIT,
      selectedUsers,
      'contains',
      this.replyChannelIsEmail(this.args.channel) ? 'email' : 'name',
    );

    let searchList: SearchListItemType[];

    if (users.length > 0) {
      this.searchHasNoResults = false;

      let selectedUsersIds = selectedUsers.map((user) => user.id);
      let unselectedUsers = users.filter((user) => !selectedUsersIds.includes(user.id));

      if (this.replyChannelIsEmail(this.args.channel)) {
        unselectedUsers = unselectedUsers.sortBy('name');
      }

      searchList = unselectedUsers.map((user) => {
        if (this.replyChannelIsEmail(this.args.channel) && !user.email) {
          return new DropdownUnselectableSuggestion(user);
        }
        return new DropdownSuggestion(user, () => this.addUser(user));
      });
    } else {
      this.searchHasNoResults = true;
      searchList = [new DropdownNoMatchForType(() => this.createNewUser(this.inputText))];
    }

    this.searchList = searchList;
  }

  replyChannelIsEmail(channel: Channel | undefined) {
    return channel && channel === Channel.Email;
  }

  @action
  onInput(e: KeyboardEvent & { target?: HTMLInputElement }) {
    let input = e.target?.value;
    if (input) {
      this.popover?.show();
    }

    let previousInputText = this.inputText;
    this.inputText = input;
    if (!this.searchHasNoResults || !input.startsWith(previousInputText)) {
      taskFor(this.searchUsers).perform(input);
    }
  }

  @action equalityComparatorFn(a: any, b: any) {
    return a.suggestion?.id === b.suggestion?.id;
  }

  @action onFocus() {
    this.isInputFocused = true;
  }

  @action focusInput() {
    schedule('afterRender', this, () => {
      this.inputElement?.focus();
      this.onFocus();
    });
  }

  @action onBlur() {
    this.isInputFocused = false;
    this.onPopoverHide();
  }

  @action onSuggestionClick(item: SearchListItemType, event: MouseEvent) {
    let isUserIdTooltip =
      event.target instanceof Element && event.target.closest('[data-is-user-id-tooltip]') !== null;
    if (isUserIdTooltip) {
      event.preventDefault();
      return;
    }

    item.onClickAction();
    later(this, this.focusInput, 20);
  }

  @action openUserProfile(workspaceId: string, userId: string) {
    let url = this.router.urlFor('apps.app.users.user', workspaceId, userId);
    safeWindowOpen(url, '_blank');
  }

  @action copyUserId(userId: string) {
    if (!this.clipboard) {
      this.clipboard = this.clipboardService.createClipboard(
        '#copy-user-id-button',
        userId,
        () => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('inbox.new-conversation.recipient-selector-tooltip.user-id-copy-success'),
          );
        },
        () => {
          this.notificationsService.notifyWarning(
            this.intl.t('inbox.new-conversation.recipient-selector-tooltip.user-id-copy-error'),
          );
        },
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ManageParticipants::UserSearchDropdown': typeof UserSearchDropdown;
    'inbox2/manage-participants/user-search-dropdown': typeof UserSearchDropdown;
  }
}
