/* import __COLOCATED_TEMPLATE__ from './ai-assist.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class OnboardingAIAssist extends Component {
  @service declare intl: IntlService;

  get items() {
    return [
      {
        icon: 'list',
        name: this.intl.t('inbox.command-k.ai-assist.insert-summary-conversation'),
      },
      {
        icon: 'list',
        name: this.intl.t('inbox.command-k.ai-assist.add-summary-conversation'),
      },
      {
        icon: 'brush',
        name: this.intl.t('inbox.command-k.ai-assist.openai-demo-expand'),
      },
      {
        icon: 'edit',
        name: this.intl.t('inbox.command-k.ai-assist.openai-demo-rephrase'),
      },
      {
        icon: 'lwr-happy',
        name: this.intl.t('inbox.command-k.ai-assist.openai-demo-friendly'),
      },
      {
        icon: 'lwr-neutral',
        name: this.intl.t('inbox.command-k.ai-assist.openai-demo-formal'),
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Onboarding::AIAssist': typeof OnboardingAIAssist;
    'inbox2/onboarding/ai-assist': typeof OnboardingAIAssist;
  }
}
