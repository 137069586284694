/* import __COLOCATED_TEMPLATE__ from './new-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import { type BlockList } from '@intercom/interblocks.ts';
import {
  ChatChannel,
  type ComposerPaneType,
  ObjectTypeForReplyChannel,
  type ReplyChannel,
  replyChannelIsChat,
  replyChannelIsEmail,
  replyChannelIsSms,
  replyChannelIsWhatsapp,
  ReplyChannelType,
} from 'embercom/objects/inbox/composer-pane';
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import { useResource } from 'ember-resources';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator, type TaskInstance } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { getLatestUpdatedParticipants } from 'embercom/objects/inbox/user-summary';
import type InboxState from 'embercom/services/inbox-state';
import { type MessageSender } from 'embercom/objects/inbox/conversation';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState, NewConversation } from 'embercom/objects/inbox/conversation';
import { type DurationObject, DurationType } from 'embercom/objects/inbox/duration';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import { SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import type IntlService from 'embercom/services/intl';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import { type ReplyDataType } from 'embercom/services/inbox-api';
import type InboxApi from 'embercom/services/inbox-api';
import RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { OutboundInitialPartSenderType } from 'embercom/objects/inbox/outbound-initial-part-sender-types';
import Chat, {
  type SenderSummary as ChatSenderSummary,
} from 'embercom/objects/inbox/renderable/chat';
import Email, {
  type SenderSummary as EmailSenderSummary,
} from 'embercom/objects/inbox/renderable/email';
import Sms from 'embercom/objects/inbox/renderable/sms';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type Snackbar from 'embercom/services/snackbar';
import { modifier } from 'ember-modifier';
import type AdminPermissions from 'embercom/services/admin-permissions';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import storage from 'embercom/vendor/intercom/storage';
import { type SendAndCloseFn, type SendAndSnoozeFn } from 'embercom/objects/inbox/types/composer';
import type EmojiService from 'embercom/services/emoji-service';
import { EntityType } from 'embercom/models/data/entity-types';
import type WhatsappTemplates from 'embercom/services/whatsapp-templates';
import WhatsappIntegrationSender from 'embercom/objects/inbox/whatsapp-integration-sender';
import AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import SenderEmailAddressSummary from 'embercom/objects/inbox/sender-email-address-summary';
import Whatsapp from 'embercom/objects/inbox/renderable/whatsapp';
import { Channel } from 'embercom/models/data/inbox/channels';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { type NewConversationWireFormat } from 'embercom/lib/inbox2/types';

import { type PublicAPI } from 'embercom/components/inbox2/conversation-reply-composer';
import { type Recipients } from 'embercom/lib/composer/recipients';
import type { VerifyAnyInstallApiResponse } from 'embercom/components/installation-new/installation-status';
import { request } from 'embercom/lib/inbox/requests';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

interface Args {
  recipientId?: string;
  ticketTypeId?: string;
  forwardedPartId?: string;
  onComposerReady?: (composerAPI: PublicAPI) => void;
}

interface Signature {
  Args: Args;
}

export interface SendOptsType {
  state?: ConversationState;
  duration?: DurationObject;
  data?: ReplyDataType;
}

export const channelSenders = {
  [ReplyChannelType.Email]: [AdminWithPermissions, SenderEmailAddressSummary],
  [ReplyChannelType.Chat]: [AdminWithPermissions],
  [ReplyChannelType.SMS]: [AdminWithPermissions],
  [ReplyChannelType.Whatsapp]: [WhatsappIntegrationSender],
};

export default class NewConversationComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare router: Router;
  @service declare inboxState: InboxState;
  @service declare intl: IntlService;
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @service declare inboxApi: InboxApi;
  @service declare inboxSidebarService: InboxSidebarService;
  @service declare snackbar: Snackbar;
  @service declare adminPermissions: AdminPermissions;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare whatsappTemplates: WhatsappTemplates;
  @service declare emojiService: EmojiService;
  @service declare greatGuidanceService: GreatGuidanceService;

  @tracked sender: MessageSender | undefined;
  @tracked replyChannel: ReplyChannel = ChatChannel;
  @tracked labelRowSize = 'w-1/4';
  @tracked visibleToUser: boolean = this.defaultIsVisibleToUser || false;
  @tracked selectedUserCompanyId: string | undefined;

  @tracked recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[] = [];
  @tracked sendSeparately = false;
  @tracked composerApi?: PublicAPI;
  conversationSent = false;
  senderSetFromDraftConversation = false;

  @ref('subject-input') declare subjectInput: HTMLElement;

  readonly newConversation: NewConversation = new NewConversation();
  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: parseInt(this.args.ticketTypeId!, 10),
    conversation: undefined,
    allowedTicketCategories: [TicketCategory.Task, TicketCategory.Request, TicketCategory.Tracker],
  }));

  readonly handleNotEditorHotkey;
  readonly toggleConversationDetailsHotkey: string;

  readonly replyChannelIsChat = replyChannelIsChat;
  readonly replyChannelIsEmail = replyChannelIsEmail;
  readonly replyChannelIsSms = replyChannelIsSms;
  readonly replyChannelIsWhatsapp = replyChannelIsWhatsapp;
  readonly getLatestUpdatedParticipants = getLatestUpdatedParticipants;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (!this.args.forwardedPartId && this.savedDraftConversation) {
      this.newConversation = NewConversation.deserialize(
        this.savedDraftConversation,
        this.session.teammate.id,
      );
      this.setDraftSender();
    } else {
      this.setSender();
      this.newConversation.adminAssignee = this.session.teammate;
      this.newConversation.teamAssignee = TeamSummary.unassigned;
    }

    if (this.args.recipientId) {
      taskFor(this.loadRecipientFromId).perform(this.args.recipientId);
    } else {
      this.setRecipients(getLatestUpdatedParticipants(storage, this.session.workspace.id));
    }

    this.handleNotEditorHotkey = this.inboxHotkeys.handleNotEditorHotkey;
    this.toggleConversationDetailsHotkey =
      this.inboxHotkeys.hotkeysMap[HotkeyID.ToggleConversationDetailsSidebar].keys;

    window.onbeforeunload = () => {
      this.saveDraftConversation();
    };

    if (this.session.workspace.isFeatureEnabled('team-product-guidance-helpdesk-setup-wip')) {
      if (!this.greatGuidanceService.isEmailInstalled) {
        this.emailSenderExists(this.newConversation.sender);
      }

      if (!this.greatGuidanceService.isSmsInstalled) {
        this.smsSenderExists();
      }

      if (!this.greatGuidanceService.isWhatsappInstalled) {
        this.whatsappSenderExists(this.newConversation.sender);
      }

      if (!this.greatGuidanceService.isMessengerInstalled) {
        taskFor(this.loadMessengerStatus).perform();
      }
    }
  }

  willDestroy() {
    super.willDestroy();
    this.saveDraftConversation();
  }

  get defaultIsVisibleToUser() {
    return this.ticketTypeResource.selectedTicketType?.category === TicketCategory.Request;
  }

  get isRecipientRequired() {
    return this.ticketTypeResource.selectedTicketType?.category !== TicketCategory.Tracker;
  }

  get savedDraftConversation() {
    return storage.get(this.conversationDraftStorageKey);
  }

  saveDraftConversation() {
    if (!this.conversationSent) {
      this.newConversation.sender = this.sender;
      storage.set(this.conversationDraftStorageKey, this.newConversation.serialize);
    }
  }

  get conversationDraftStorageKey() {
    return `conversation-draft-${this.session.workspace.id}`;
  }

  get canCreateConversation(): boolean {
    return this.recipientSelected && !!this.sender && !this.hasRecipientError;
  }

  get recipientSelected(): boolean {
    if (!this.newConversation.userSummary) {
      return false;
    }

    let { id, email } = this.newConversation.userSummary;
    return !!id || !!email;
  }

  get shouldAddRecipientDataAttribute(): boolean {
    return this.replyChannelIsEmail(this.replyChannel);
  }

  get hasRecipientError() {
    return this.recipientsWithErrors.any(({ recipientError }) => recipientError !== undefined);
  }

  @action setRecipientErrors(
    recipientsWithErrors: { recipient: UserSummary; recipientError: string | void }[],
  ) {
    this.recipientsWithErrors = recipientsWithErrors;
  }

  get isCreatingConversation() {
    return !this.ticketTypeResource.selectedTicketType;
  }

  get classNameIfSideBarIsCollapsed() {
    if (this.inboxSidebarService.isSidebarCollapsed) {
      return 'has-collapsed-sidebar';
    }

    return '';
  }

  @action onComposerReady(composerApi: PublicAPI) {
    this.composerApi = composerApi;
  }

  @action insertBlocks(blocks: BlockList) {
    this.composerApi?.api.composer.commands.insertBlocks(blocks);
    this.composerApi?.api.composer.commands.focus();
  }

  get defaultReplyChannel() {
    if (this.args.forwardedPartId) {
      return Channel.Email;
    }
    return undefined;
  }

  get ticketTypesByCategories() {
    let categories = [
      {
        category: TicketCategory.Task,
        heading: this.intl.t('inbox.new-conversation.ticket-type-dropdown.tickets-task-heading'),
      },
      {
        category: TicketCategory.Request,
        heading: this.intl.t('inbox.new-conversation.ticket-type-dropdown.tickets-request-heading'),
      },
      {
        category: TicketCategory.Tracker,
        heading: this.intl.t('inbox.new-conversation.ticket-type-dropdown.tickets-tracker-heading'),
      },
    ];

    let sections = categories.map((category) => {
      return this.ticketSection(category.heading, category.category);
    });

    return sections;
  }

  hasTicketTypes(ticketCategory: TicketCategory) {
    return (
      this.ticketTypeResource.activeTicketTypes.filter(
        ({ category }) => category === ticketCategory,
      ).length > 0
    );
  }

  ticketSection(sectionHeading: string, ticketCategory: TicketCategory) {
    let items = this.ticketTypeResource.activeTicketTypes
      .filter(({ category }) => category === ticketCategory)
      .sortBy('name')
      .map((type) => ({
        text: type.name,
        value: type.id.toString(),
        onSelectItem: () => this.createNewTicket(type.id),
      }));

    return {
      heading: sectionHeading,
      isFilterable: true,
      items,
    };
  }

  @action createNewTicket(ticketTypeId: number) {
    this.router.transitionTo('inbox.workspace.inbox.new-conversation', {
      queryParams: {
        ticketTypeId,
      },
    });
  }

  @task
  *loadRecipientFromId(recipientId: string): TaskGenerator<void> {
    let user = yield this.inboxSearchSuggestionsService.loadUserFromId(recipientId);
    this.newConversation.isRecipientLoadedFromQueryParams = !!user;
    this.setRecipients(user ? [user] : []);
  }

  @action selectSubjectField() {
    this.subjectInput?.focus();
  }

  getEntityType(channel: ReplyChannel): number | undefined {
    switch (channel.type) {
      case ReplyChannelType.Email:
        return EntityType.Email;
      case ReplyChannelType.Chat:
        return EntityType.Chat;
      case ReplyChannelType.Whatsapp:
        return EntityType.Whatsapp;
      case ReplyChannelType.SMS:
        return EntityType.Sms;
      default:
        return EntityType.Chat;
    }
  }

  @action onChannelChange(channel: ReplyChannel) {
    let fromChannel = this.getEntityType(this.replyChannel);
    let toChannel = this.getEntityType(channel);

    this.replyChannel = channel;
    this.setSender();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'switched',
      object: 'channel',
      section: 'new_conversation',
      from_channel: fromChannel,
      to_channel: toChannel,
      num_participants: this.newConversation.participants.length,
    });
  }

  @action onSenderChange(sender: WhatsappIntegrationSender) {
    this.sender = sender;
    this.setWhatsappActiveIntegration(sender);
  }

  @action onVisibilityChange(visibility_change: boolean) {
    this.visibleToUser = visibility_change;
  }

  get isCreatingTicket() {
    return taskFor(this.createTicket).isRunning;
  }

  get isTicketRecipientMissing() {
    return (
      this.isRecipientRequired &&
      this.newConversation?.userSummary?.email === undefined &&
      this.newConversation?.userSummary?.id === undefined
    );
  }

  @task({ drop: true })
  *createTicket() {
    if (this.isTicketRecipientMissing || this.ticketTypeResource.hasInvalidTicketAttributes) {
      this.snackbar.notifyError(
        this.intl.t('inbox.new-conversation.errors.invalid-ticket-attributes'),
      );
      return;
    }

    let conversation = this.newConversation;
    let data = {
      app_id: this.session.workspace.id,
      admin_assignee_id: conversation.adminAssignee?.id,
      team_assignee_id: conversation.teamAssignee?.id,
      ticket_type_id: this.ticketTypeResource.selectedTicketType!.id,
      visible_to_user: this.visibleToUser,
      company_id: this.selectedUserCompanyId,
    } as Record<string, unknown>;
    if (conversation.userSummary?.isNewUser) {
      data.new_user_email = conversation.userSummary?.email;
    } else {
      data.user_id = conversation.userSummary?.id;
    }

    data.attributes = this.ticketTypeResource.ticketAttributes
      ?.filter((attributeSummary: TicketAttributeSummary) => attributeSummary.value !== undefined)
      ?.map((attributeSummary: TicketAttributeSummary) => {
        return {
          descriptor_id: attributeSummary.descriptor.id,
          value: attributeSummary.serializedValue,
        };
      });

    try {
      let ticket: Conversation = yield this.inboxApi.createNewTicket(data);

      this.trackTicketCreationEvent();

      this.snackbar.notify(
        this.intl.t('inbox.new-conversation.new-ticket-created', {
          ticket_type: this.ticketTypeResource.selectedTicketType!.name,
        }),
      );
      this.router.transitionTo('inbox.workspace.inbox.conversation.conversation', ticket.id);
    } catch (e) {
      this.snackbar.notifyError(
        this.intl.t('inbox.new-conversation.errors.problem-creating-ticket'),
      );
    }
  }

  trackTicketCreationEvent() {
    let eventData: Record<string, string | boolean> = {
      object: 'ticket',
      action: 'created',
      section: 'new_conversation',
      ticket_category: this.ticketTypeResource.selectedTicketType!.category,
    };

    if (this.isRecipientRequired) {
      eventData = {
        ...eventData,
        company_associated: !!this.selectedUserCompanyId,
        default_company_selection_changed: this.defaultCompanyId() !== this.selectedUserCompanyId,
      };
    }

    this.intercomEventService.trackAnalyticsEvent(eventData);
  }

  @action
  async onSend(
    _type: ComposerPaneType,
    blocks: BlockList,
    macroActions: Array<MacroAction>,
    paneChannel: ReplyChannelType,
    data: ReplyDataType,
  ) {
    return this.send(blocks, paneChannel, macroActions, { data });
  }

  onSendAndClose: SendAndCloseFn = async (blocks, macroActions, paneChannel, data) => {
    if (paneChannel === null || paneChannel === undefined) {
      throw new Error('paneChannel must be set to create a new conversation');
    }

    return this.send(blocks, paneChannel, macroActions, { state: ConversationState.Closed, data });
  };

  onSendAndSnooze: SendAndSnoozeFn = async (blocks, macroActions, duration, paneChannel, data) => {
    if (paneChannel === null || paneChannel === undefined) {
      throw new Error('paneChannel must be set to create a new conversation');
    }

    return this.send(blocks, paneChannel, macroActions, { duration, data });
  };

  async send(
    blocks: BlockList,
    paneChannel: ReplyChannelType,
    macroActions: Array<MacroAction>,
    opts?: SendOptsType,
  ) {
    let data = this.buildConversationData(blocks, paneChannel, opts);
    let result = null;
    if (this.sendSeparately) {
      result = await this.sendSeparatelyToParticipants(data, macroActions);
    } else {
      this.addParticipants(data);
      result = await taskFor(this.createConversation).perform(data, macroActions);
    }

    if (result) {
      storage.remove(this.conversationDraftStorageKey);
      return {};
    } else {
      return {
        errors: ['Failed to create new conversation(s)'],
      };
    }
  }

  private trackConversationCreation(
    conversationId: number,
    channel: number,
    numParticipants: number,
  ) {
    this.trackEmailBrand(conversationId, channel);
    if (numParticipants > 1) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'conversation',
        action: 'created',
        section: 'new_conversation',
        conversation_id: conversationId,
        channel,
        num_participants: numParticipants,
        send_separately: this.sendSeparately,
        forwarded_message: !!this.args.forwardedPartId,
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'conversation',
        action: 'created',
        section: 'new_conversation',
        conversation_id: conversationId,
        channel,
        forwarded_message: !!this.args.forwardedPartId,
      });
    }
  }

  private trackEmailBrand(conversationId: number, channel: number) {
    if (channel === EntityType.Email) {
      this.intercomEventService.trackAnalyticsEvent({
        object: 'email',
        action: 'email_sent',
        section: 'new_conversation',
        conversation_id: conversationId,
        sender_email_id: this.sender?.id,
      });
    }
  }

  @task
  *createConversation(data: NewConversationWireFormat, macroActions: MacroAction[]) {
    try {
      this.newConversation.addRenderablePart(this.createRenderablePart(data));

      let conversation = (yield this.inboxState.createNewConversation(
        data,
        macroActions,
        !this.sendSeparately,
      )) as {
        id: number;
      };

      if (!this.sendSeparately) {
        if (data.new_users && data.user_ids) {
          let numParticipants = data.new_users.length + data.user_ids.length;
          this.trackConversationCreation(conversation.id, data.type, numParticipants);
        }

        this.redirectToConversation(conversation.id);
      }
      this.conversationSent = true;
      return conversation.id;
    } catch (e) {
      if (!this.sendSeparately) {
        let error = (yield e?.response?.json()) as { errors: string };

        let errorText =
          error?.errors || 'There was a problem starting this conversation, please try again.';
        this.snackbar.notifyError(errorText);
      }
      return null;
    }
  }

  private getConversationState(state: ConversationState) {
    return {
      [ConversationState.Open]: 0,
      [ConversationState.Closed]: 1,
      [ConversationState.Snoozed]: 2,
    }[state];
  }

  get sidebarSections(): SupportedSidebarSection[] {
    let isCreatingTicket = this.args.ticketTypeId !== undefined;
    let hasSelectedUser = this.newConversation?.userSummary !== undefined;
    let isTrackerTicket = this.ticketTypeResource.selectedTicketType?.isTrackerTicketType;

    if (isCreatingTicket && hasSelectedUser && !isTrackerTicket) {
      return [SupportedSidebarSection.UserDetails];
    }

    if (isCreatingTicket) {
      return [];
    }

    if (!this.newConversation?.userSummary) {
      return [SupportedSidebarSection.ConversationDetails];
    }

    let sections = [
      SupportedSidebarSection.ConversationDetails,
      SupportedSidebarSection.UserDetails,
      SupportedSidebarSection.Notes,
    ];
    let isNewUser = this.newConversation.userSummary?.isNewUser;
    if (!isNewUser) {
      sections.push(SupportedSidebarSection.LatestConversations);
    }

    return sections;
  }

  get openSidebarSections(): string[] {
    return [
      SupportedSidebarSection.UserDetails.toString(),
      SupportedSidebarSection.ConversationDetails.toString(),
    ];
  }

  // Returns a temporary renderable part object that we display while saving a conversation.
  private createRenderablePart(data: NewConversationWireFormat): RenderablePart {
    if (data.type === objectTypes.chat) {
      let renderableData = new Chat(data.blocks, this.sender as ChatSenderSummary, this.senderType);
      return new RenderablePart(-1, RenderableType.Chat, renderableData, new Date(), 0, 0);
    } else if (data.type === objectTypes.sms) {
      let renderableData = new Sms(data.blocks, this.senderType);
      return new RenderablePart(-1, RenderableType.Sms, renderableData, new Date(), 0, 0);
    } else if (data.type === objectTypes.whatsapp) {
      let renderableData = new Whatsapp(data.blocks, this.session.teammate, this.senderType);
      return new RenderablePart(-1, RenderableType.Whatsapp, renderableData, new Date(), 0, 0);
    } else {
      let renderableData = new Email(
        data.blocks,
        this.sender as EmailSenderSummary,
        this.senderType,
        this.newConversation.title,
      );
      return new RenderablePart(-1, RenderableType.Email, renderableData, new Date(), 0, 0);
    }
  }

  get senderType() {
    return this.sender?.id === this.session.teammate.id
      ? OutboundInitialPartSenderType.Admin
      : OutboundInitialPartSenderType.CustomEmailAddress;
  }

  get Inbox1SenderType() {
    return this.senderType === OutboundInitialPartSenderType.Admin
      ? senderTypes.admin
      : senderTypes.customEmailAddress;
  }

  isSenderCompatible(sender: MessageSender, channelType: ReplyChannelType) {
    return channelSenders[channelType]?.any((senderType: any) => sender instanceof senderType);
  }

  async emailSenderExists(sender: MessageSender | undefined) {
    try {
      let customEmailAddresses = await this.inboxApi.fetchSenderEmailAddresses();
      let exists = customEmailAddresses.any((address) => address.id === sender?.id);
      this.greatGuidanceService.isEmailInstalled = customEmailAddresses.length > 0;
      return exists;
    } catch (e) {
      return false;
    }
  }

  async whatsappSenderExists(sender: MessageSender | undefined) {
    try {
      let senders = await this.inboxApi.fetchWhatsappIntegrations({ no_external_updates: true });
      let exists = senders.any((address) => address.id === sender?.id);
      this.greatGuidanceService.isWhatsappInstalled = senders.length > 0;
      return exists;
    } catch (e) {
      return false;
    }
  }

  async smsSenderExists() {
    try {
      let response = await request(`/ember/sms/phone_number?app_id=${this.session.workspace.id}`);
      let data = await response.json();
      let exists = data.length > 0;
      this.greatGuidanceService.isSmsInstalled = exists;
      return exists;
    } catch (e) {
      return false;
    }
  }

  async senderExists(sender: MessageSender | undefined) {
    if (sender?.id === this.session.teammate.id) {
      return true;
    }

    if (await this.emailSenderExists(sender)) {
      return true;
    }

    return await this.whatsappSenderExists(sender);
  }

  async setDraftSender() {
    if (await this.senderExists(this.newConversation.sender)) {
      this.sender = this.newConversation.sender;
      this.senderSetFromDraftConversation = true;
      return;
    }
    await this.setSender();
  }

  setWhatsappActiveIntegration(sender: WhatsappIntegrationSender) {
    if (this.replyChannelIsWhatsapp(this.replyChannel)) {
      this.whatsappTemplates.activeIntegration = sender;
    }
  }

  async setSender() {
    if (this.senderSetFromDraftConversation && this.sender) {
      this.senderSetFromDraftConversation = false;
      if (this.sender && this.isSenderCompatible(this.sender, this.replyChannel.type)) {
        this.setWhatsappActiveIntegration(this.sender as WhatsappIntegrationSender);
        return;
      }
    }

    if (this.replyChannelIsWhatsapp(this.replyChannel)) {
      let whatsappSenders = await this.inboxApi.fetchWhatsappIntegrations({
        no_external_updates: true,
      });
      this.sender = whatsappSenders[0];
      this.setWhatsappActiveIntegration(this.sender);
    } else {
      await this.session.workspace.fetchEmailSenderSettings();
      this.sender = this.useDefaultSenderEmailAddress
        ? this.defaultSenderEmailAddress
        : this.session.teammate;
    }
  }

  get useDefaultSenderEmailAddress() {
    return (
      this.replyChannelIsEmail(this.replyChannel) &&
      this.adminPermissions.canSendFromCustomAddresses &&
      !!this.defaultSenderEmailAddress
    );
  }

  get defaultSenderEmailAddress() {
    return this.session.workspace.emailSenderSettings?.adminReplyDefaultAddress;
  }

  @action setRecipients(recipients?: UserSummary[]) {
    if (this.args.recipientId && recipients?.length !== 1) {
      // Wipe the query param recipient if you select another
      this.router.transitionTo({ queryParams: { recipient: undefined } });
    }

    this.newConversation.participants = recipients ?? [];
    this.selectedUserCompanyId = this.defaultCompanyId();
  }

  @action setRecipientsDataType(recipients?: Recipients) {
    if (!recipients) {
      return;
    }
    //TODO This seems odd, where we set the recipients attribute to be the wire format.
    this.newConversation.recipients = recipients.serialize();
  }

  private defaultCompanyId(): string | undefined {
    let userCompanyIds: string[] = this.userCompanyIds();
    if (userCompanyIds.length === 1) {
      return userCompanyIds[0];
    } else {
      return undefined;
    }
  }

  private userCompanyIds(): string[] {
    if (!this.newConversation?.userSummary?.companies) {
      return [];
    } else {
      return this.newConversation.userSummary.companies.map((company) => company.id);
    }
  }

  @action setSelectedUserCompanyId(id: string | null) {
    if (id === null) {
      this.selectedUserCompanyId = undefined;
    } else {
      this.selectedUserCompanyId = id;
    }
  }

  setLabelWidth = modifier((element: Element) => {
    if (element.clientWidth > 1000) {
      this.labelRowSize = 'w-1/4';
    } else {
      this.labelRowSize = 'w-1/3';
    }
  });

  private buildConversationData(
    blocks: BlockList,
    paneChannel: ReplyChannelType,
    opts?: SendOptsType,
  ): NewConversationWireFormat {
    let conversation = this.newConversation;
    let data: NewConversationWireFormat = {
      app_id: this.session.workspace.id,
      type: ObjectTypeForReplyChannel[paneChannel],
      blocks,
      subject: conversation.title,
      admin_assignee_id: conversation.adminAssignee?.id,
      team_assignee_id: conversation.teamAssignee?.id,
      sender_id: this.sender?.id,
      sender_type: this.Inbox1SenderType,
      forwarded_part_id: this.args.forwardedPartId,
      recipients: conversation.recipients,
    };

    if (opts) {
      if (opts.state) {
        data.state = this.getConversationState(opts.state);
      }

      if (opts.duration) {
        data.snoozed_until = opts.duration.type;
        data.author_timezone = opts.duration.timezone;

        if (opts.duration.type === DurationType.CustomTime) {
          data.custom_snoozed_until_time = opts.duration.time;
        }
      }

      if ('data' in opts && paneChannel === ReplyChannelType.Whatsapp) {
        data.data = {
          whatsapp: {
            integration_id: this.sender?.id,
            template_name: opts.data?.whatsapp_template_name,
            template_language: opts.data?.whatsapp_template_language,
            template_components: opts.data?.whatsapp_template_components,
          },
        };
      }
    }

    return data;
  }

  addParticipants(data: NewConversationWireFormat) {
    let new_users: { email_address?: string }[] = [];
    let user_ids: string[] = [];

    this.newConversation.participants.forEach((user) =>
      user.isNewUser ? new_users.push({ email_address: user.email }) : user_ids.push(user.id),
    );

    data.new_users = new_users;
    data.user_ids = user_ids;
  }

  async sendSeparatelyToParticipants(data: NewConversationWireFormat, macroActions: MacroAction[]) {
    let promises: TaskInstance<number | null>[] = [];

    if (this.session.workspace.isFeatureEnabled('team-channels-cc')) {
      this.newConversation.recipients?.to.ids.forEach((user: string) => {
        data.recipients = {
          to: {
            ids: [user],
            emails: [],
          },
          cc: {
            ids: [],
            emails: [],
          },
        };

        promises.push(taskFor(this.createConversation).perform(data, macroActions));
      });

      this.newConversation.recipients?.to.emails.forEach((email: string) => {
        data.recipients = {
          to: {
            ids: [],
            emails: [email],
          },
          cc: {
            ids: [],
            emails: [],
          },
        };

        promises.push(taskFor(this.createConversation).perform(data, macroActions));
      });
    } else {
      this.newConversation.participants.forEach((user) => {
        if (user.isNewUser) {
          data.new_users = [{ email_address: user.email }];
          data.user_ids = [];
        } else {
          data.new_users = [];
          data.user_ids = [user.id];
        }
        promises.push(taskFor(this.createConversation).perform(data, macroActions));
      });
    }

    let conversationsIds = await Promise.all(promises);
    let createdConversationsIds = conversationsIds.filter((id): id is number => id !== null);
    let failedConversationsCount =
      this.newConversation.participants.length - createdConversationsIds.length;
    if (failedConversationsCount === 0) {
      this.snackbar.notify(this.intl.t('inbox.notifications.conversation-sent-successfully'));
    } else {
      this.snackbar.notifyError(
        this.intl.t('inbox.notifications.conversation-failed', {
          number: failedConversationsCount,
        }),
      );
    }

    if (createdConversationsIds.length > 0) {
      this.trackConversationCreation(
        createdConversationsIds[0],
        data.type,
        createdConversationsIds.length,
      );
      this.redirectToConversation(createdConversationsIds[0]);
      return createdConversationsIds[0];
    }
    return null;
  }

  @action toggleSendSeparately() {
    this.sendSeparately = !this.sendSeparately;
    if (this.sendSeparately) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'turned_on',
        object: 'send_separately_toggle',
        section: 'new_conversation',
      });
    }
  }

  redirectToConversation(conversationId: number) {
    this.router.transitionTo('inbox.workspace.inbox.conversation.conversation', conversationId);
  }

  get isMessengerInstalled() {
    return this.greatGuidanceService.isMessengerInstalled;
  }

  get isWhatsappInstalled() {
    return this.greatGuidanceService.isWhatsappInstalled;
  }

  get isSmsInstalled() {
    return this.greatGuidanceService.isSmsInstalled;
  }

  get isEmailInstalled() {
    return this.greatGuidanceService.isEmailInstalled;
  }

  @task
  *loadMessengerStatus(): TaskGenerator<any> {
    yield this.getMessengerStatus();
  }

  async getMessengerStatus(): Promise<void> {
    try {
      let url = `/ember/messenger_install_verification/verify_any_install?app_id=${this.session.workspace.id}`;
      let response = await request(url);
      let json = (await response.json()) as VerifyAnyInstallApiResponse;
      let isInstalledForVisitors = !!json.anonymous_request_at;
      let isInstalledForUsers = !!json.identified_request_at;
      let isInstalledForIos = json.ios_is_activated;
      let isInstalledForAndroid = json.android_is_activated;

      this.greatGuidanceService.isMessengerInstalled =
        isInstalledForVisitors || isInstalledForUsers || isInstalledForIos || isInstalledForAndroid;
    } catch (e) {
      this.greatGuidanceService.isMessengerInstalled = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewConversation': typeof NewConversationComponent;
    'inbox2/new-conversation': typeof NewConversationComponent;
  }
}
