/* import __COLOCATED_TEMPLATE__ from './search2.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';
import type Session from 'embercom/services/session';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import type Inbox2TagsSearch from 'embercom/services/inbox2-tags-search';
import { action } from '@ember/object';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import { isEmpty } from '@ember/utils';
import type RouterService from '@ember/routing/router-service';
import { isEqual } from 'underscore';
import ENV from 'embercom/config/environment';

// @ts-ignore
import { cached } from 'tracked-toolbox';
import PredicateGroup, {
  type DateRangePredicate,
  type NestedPredicate,
  type SimplePredicate,
  toAbsoluteDate,
  fromAbsoluteDate,
  type BooleanComparison,
  type Comparison,
  type TicketStatePredicate,
} from 'embercom/objects/inbox/search/predicate-group';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type Tag from 'embercom/objects/inbox/tag';
import type ConversationTopic from 'embercom/objects/inbox/conversation-topic';
import { restartableTask } from 'ember-concurrency-decorators';
import { all, type TaskGenerator, timeout } from 'ember-concurrency';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type UserApi from 'embercom/services/user-api';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import type CompanyApi from 'embercom/services/company-api';
import {
  InboxSearchSort,
  type InboxStateOption,
  isTicketState,
  intToState,
  stateToInt,
  InboxConversationTypeOption,
} from 'embercom/models/data/inbox/inbox-filters';
import type IntlService from 'embercom/services/intl';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type ExperimentsApi from 'embercom/services/experiments-api';
import type moment from 'moment-timezone';
import { type ColumnDefinition } from 'embercom/objects/inbox/conversations-table-column';
import type ConversationsTableColumn from 'embercom/objects/inbox/conversations-table-column';
import {
  ColumnDefinitionsBuilder,
  ConversationsTableColumns,
  columnId,
} from 'embercom/objects/inbox/conversations-table-column';
import { type SortState } from './conversations-table';
import { type SortDirection } from 'embercom/services/inbox-api';
import { type SelectedColumn } from 'embercom/services/conversations-table-settings';
import type ConversationsTableSettings from 'embercom/services/conversations-table-settings';
import { tracked } from '@glimmer/tracking';
import type ConversationalInsightsApi from 'embercom/services/inbox2-conversational-insights-api';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
// @ts-ignore
import { registerDestructor } from '@ember/destroyable';
import { type TicketDescriptor, type TicketType } from 'embercom/objects/inbox/ticket';
import type InboxApi from 'embercom/services/inbox-api';
import type InboxState from 'embercom/services/inbox-state';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import { REQUIRED_CONVERSATIONS_TABLE_FIELDS } from 'embercom/objects/inbox/conversation-table-entry';
import { type ConversationsSearchResource } from './search/conversations-search-resource';
import type InboxRedirectionService from 'embercom/services/inbox-redirection-service';
import { type TicketSystemState } from 'embercom/objects/inbox/conversation';

interface Args {
  filters?: string;
  query?: string;
  sort?: InboxSearchSort;
}

interface SortOption {
  id: InboxSearchSort;
  text: string;
  sortState: SortState;
}

export interface MoreFiltersGroup {
  heading?: string;
  items: Array<MoreFiltersItem>;
  id: number;
}

export interface MoreFiltersItem {
  attribute: ConversationAttributeDescriptor | TicketDescriptor;
  value?: string | CvdaDateTimeItem['value'] | number | null;
  comparison?: Comparison;
  groupId: number;
}

export interface CvdaListItem {
  text: string;
  id: string;
}

export interface CvdaDateTimeItem {
  value: {
    value: string;
    unit: 'days' | 'hours' | 'minutes';
    direction: 'past';
  };
  comparison: Comparison;
}

const convertStringIdToNumber = (id?: string | number | null) => {
  return id && typeof id === 'string' ? parseFloat(id) : (id as number | null | undefined);
};

export default class Search2Component extends Component<Args> {
  @service declare session: Session;
  @service declare router: RouterService;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;
  @service declare inbox2TagsSearch: Inbox2TagsSearch;
  @service declare userApi: UserApi;
  @service declare companyApi: CompanyApi;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare experimentsApi: ExperimentsApi;
  @service declare conversationsTableSettings: ConversationsTableSettings;
  @service declare inbox2ConversationalInsightsApi: ConversationalInsightsApi;
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare inboxRedirectionService: InboxRedirectionService;

  @tracked activeSortOption: SortOption = this.initialSortOption();
  private _conversationAttributeDescriptors: ConversationAttributeDescriptor[] = [];
  private _ticketTypes: TicketType[] = [];
  @tracked _allFilterItems: MoreFiltersGroup[] = [];
  @tracked selectedFiltersItems: MoreFiltersGroup[] = [];
  @tracked filtersQuery = '';
  @tracked filtersToShowTopLevel: MoreFiltersItem[] = [];
  @tracked descriptors: ConversationAttributeDescriptor[] = [];
  @tracked ticketTypes: TicketType[] = [];
  @tracked tableColumns?: ConversationsTableColumns;

  readonly DEFAULT_ROW_HEIGHT_RELEVANT_SEARCH = 155;
  readonly CREATED_BY_TEAMMATE_ATTRUBUTE_IDENTIFIER =
    'conversation.custom_attribute.created_by_teammate_id';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadConversationAndTicketAttributes).perform();
    taskFor(this.loadDynamicColumns).perform(owner);

    registerDestructor(this, () => {
      taskFor(this.loadConversationAndTicketAttributes).cancelAll();
      taskFor(this.loadDynamicColumns).cancelAll();
    });
  }

  get cvdaHeading() {
    return this.intl.t('inbox.search.filters.more-filters.conversation-data-attributes');
  }

  @task
  *loadConversationAndTicketAttributes() {
    yield all([this.loadConversationAttributeDescriptors(), this.loadTicketAttributeDescriptors()]);

    this.selectedFiltersItems = this.deepCopyFilters([
      ...this.withoutCategoryFilteredItems,
      ...this.selectedFiltersItems,
    ]);
    this._allFilterItems = [...this.withoutCategoryFilteredItems, ...this._allFilterItems];
    this.loadTopLevelFiltersFromUrl();
  }

  async loadConversationAttributeDescriptors() {
    let attrs = await this.getConversationAttributeDescriptors();

    this._conversationAttributeDescriptors = attrs.reject(
      (attr) => attr.archived || attr.isFilesType,
    );

    let conversationAttributeDescriptorsWithCategoryConversation =
      this._conversationAttributeDescriptors.filter(
        (attribute) => attribute.category === 'conversation',
      );

    this._allFilterItems = [
      ...this._allFilterItems,
      {
        heading: this.cvdaHeading,
        items: conversationAttributeDescriptorsWithCategoryConversation
          ? conversationAttributeDescriptorsWithCategoryConversation.map((attr) => ({
              attribute: attr,
              groupId: -1,
            }))
          : [],
        id: -1,
      },
    ];

    this.selectedFiltersItems = this.deepCopyFilters(this._allFilterItems);
  }

  async loadTicketAttributeDescriptors() {
    let ticketTypes = await this.inboxApi.listTicketTypes();

    this._ticketTypes = ticketTypes.filter((ticketType) => !ticketType.archived);

    this._ticketTypes.forEach((ticketType) => {
      let attrs = ticketType.descriptors?.filter(
        (attr) => !attr.archived && attr.isListedAsAttribute && !attr.isFilesType,
      );

      if (attrs && attrs.length > 0) {
        this._allFilterItems = [
          ...this._allFilterItems,
          {
            heading: ticketType.name,
            items: attrs.map((attr) => ({
              attribute: attr,
              groupId: ticketType.id,
            })),
            id: ticketType.id,
          },
        ];
      }
    });
    this.selectedFiltersItems = JSON.parse(JSON.stringify(this._allFilterItems));
  }

  async getConversationAttributeDescriptors(): Promise<ConversationAttributeDescriptor[]> {
    return this.session.workspace.fetchConversationAttributeDescriptors();
  }

  private get withoutCategoryFilteredItems() {
    let systemAttributesWithoutCategory = this._conversationAttributeDescriptors.filter(
      (attribute) => !attribute.category,
    );

    let filterItems = [
      {
        items: systemAttributesWithoutCategory
          ? systemAttributesWithoutCategory.map((attr) => ({
              attribute: attr,
              groupId: -2,
            }))
          : [],
        id: -2,
      },
    ];

    return filterItems;
  }

  private deepCopyFilters(filters: MoreFiltersGroup[]) {
    return JSON.parse(JSON.stringify(filters));
  }

  private loadTopLevelFiltersFromUrl() {
    if (this.cvdaPredicate && this.cvdaPredicate.predicates.length > 0) {
      this.filtersToShowTopLevel = this.cvdaPredicate.predicates.reduce<MoreFiltersItem[]>(
        (filters, predicate) => {
          let conversationAttribute = this.getConversationAttributeFromPredicate(predicate);
          let ticketAttribute = this.getTicketAttributeFromPredicate(predicate);

          // Id is unique among conversation or ticket attributes. So we will have either a conversation attribute or ticket attribute in the predicate
          let conversationOrTicketAttribute = conversationAttribute || ticketAttribute;

          if (conversationOrTicketAttribute) {
            // Push the attribute to top level filters
            filters.push({
              attribute: conversationOrTicketAttribute.attribute,
              value: predicate.value || undefined,
              comparison: predicate.comparison,
              groupId: conversationOrTicketAttribute.groupId,
            });
            // Remove the attribute from More filters dropdown
            this.selectedFiltersItems = this.selectedFiltersItems.map((group) => {
              if (group.id === conversationOrTicketAttribute!.groupId) {
                return {
                  heading: group.heading,
                  items: group.items.filter(
                    (item) => item.attribute.id !== conversationOrTicketAttribute!.attribute.id,
                  ),
                  id: conversationOrTicketAttribute!.groupId,
                };
              }
              return group;
            });
          }
          return filters;
        },
        [],
      );
    }
  }

  private getConversationAttributeFromPredicate(
    predicate: Omit<SimplePredicate, 'identifier'>,
  ): { attribute: ConversationAttributeDescriptor; groupId: number } | undefined {
    let attribute = this._conversationAttributeDescriptors?.find((attr) => {
      return (
        attr.id.toString() === predicate.attribute.replace('conversation.custom_attribute.', '')
      );
    });
    return attribute ? { attribute, groupId: -1 } : undefined;
  }

  private getTicketAttributeFromPredicate(
    predicate: Omit<SimplePredicate, 'identifier'>,
  ): { attribute: TicketDescriptor; groupId: number } | undefined {
    let attribute = this._ticketTypes
      // Get descriptors array from each ticket type
      .map((ticketType) => ticketType.descriptors || [])
      // Flatten the array of descriptor array into 1D array
      .flatMap((descriptors) => descriptors)
      // Find the attribute from predicate
      .find((attr) => {
        return (
          attr.id.toString() === predicate.attribute.replace('conversation.custom_attribute.', '')
        );
      });
    return attribute ? { attribute, groupId: attribute.ticketTypeId } : undefined;
  }

  get showMoreFiltersPlaceholder() {
    return taskFor(this.loadConversationAndTicketAttributes).isRunning;
  }

  get conversationAttributeDescriptors() {
    return this._conversationAttributeDescriptors;
  }

  private getFiltersExcludingTopLevel() {
    return this._allFilterItems.map((group) => {
      return {
        heading: group.heading,
        items: group.items.filter((item) => {
          return !this.filtersToShowTopLevel.find(
            (filter) => filter.attribute.id === item.attribute.id,
          );
        }),
        id: group.id,
      };
    });
  }

  @action onFiltersQueryChanged(query: string) {
    this.filtersQuery = query;
    if (query) {
      let lowerCaseQuery = query.toLowerCase();
      this.selectedFiltersItems = this.getFiltersExcludingTopLevel().map((group) => {
        return {
          heading: group.heading,
          items: group.items.filter((item) => {
            return item.attribute.name.toLowerCase().includes(lowerCaseQuery);
          }),
          id: group.id,
        };
      });
    } else {
      this.selectedFiltersItems = this.getFiltersExcludingTopLevel();
    }
  }

  @action selectFilter(item: MoreFiltersItem) {
    this.filtersQuery = '';
    this.filtersToShowTopLevel = [...this.filtersToShowTopLevel, item];
    this.selectedFiltersItems = this.getFiltersExcludingTopLevel().map((group) => {
      return {
        heading: group.heading,
        items: group.items.filter((i) => i.attribute.id !== item.attribute.id),
        id: group.id,
      };
    });
  }

  @action onMoreFilterItemValueUpdated(
    item: MoreFiltersItem,
    value: string | BooleanComparison | CvdaDateTimeItem['value'],
    comparison: Comparison = 'eq',
  ) {
    let predicate: NestedPredicate['predicates'][number] = {
      attribute: `conversation.custom_attribute.${item.attribute.id}`,
      comparison: item.attribute.type === 'boolean' ? (value as BooleanComparison) : comparison,
      type: this.getPredicateType(item.attribute),
      value,
    };

    if (this.cvdaPredicate) {
      this.cvdaPredicate.predicates = this.cvdaPredicate.predicates.filter(
        (predicate) => predicate.attribute !== `conversation.custom_attribute.${item.attribute.id}`,
      );
      this.cvdaPredicate.predicates.pushObject(predicate);
    } else {
      this.predicateGroup.add({
        identifier: 'conversation-attribute',
        type: 'and',
        predicates: [predicate],
      });
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  private getPredicateType(
    item: ConversationAttributeDescriptor | TicketDescriptor,
  ): DataType | 'conversation_attribute_list' | 'float' {
    if (item.type === DataType.List) {
      return 'conversation_attribute_list';
    } else if (item.type === DataType.Decimal) {
      return 'float';
    }
    return item.type;
  }

  @action onMoreFilterItemRemoved(item: MoreFiltersItem) {
    this.filtersToShowTopLevel = this.filtersToShowTopLevel.filter(
      (i) => i.attribute.id !== item.attribute.id,
    );

    item.value = undefined;

    this.selectedFiltersItems = this.selectedFiltersItems.map((group) => {
      if (group.id === item.groupId) {
        return {
          heading: group.heading,
          items: [...group.items, item],
          id: group.id,
        };
      }
      return group;
    });

    if (this.cvdaPredicate) {
      this.cvdaPredicate.predicates = this.cvdaPredicate.predicates.filter(
        (predicate) => predicate.attribute !== `conversation.custom_attribute.${item.attribute.id}`,
      );
      if (this.cvdaPredicate.predicates.length === 0) {
        this.predicateGroup.remove('conversation-attribute');
      }
    }
    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  private clearMoreFilters() {
    this.filtersToShowTopLevel = [];
    this.selectedFiltersItems = this.getFiltersExcludingTopLevel();
  }

  initialSortOption(): SortOption {
    let fallbackSortOption = this.relevanceSortOption;
    let selectedSortOption;
    if (this.args.sort) {
      selectedSortOption = this.sortOptions.find((option) => option.id === this.args.sort);
      this.saveSortSettings(selectedSortOption);
    } else if (this.settings.selectedSortOption) {
      selectedSortOption = this.sortOptions.find((option) =>
        isEqual(option.sortState, this.settings.selectedSortOption),
      );
    }
    return selectedSortOption || fallbackSortOption;
  }

  get hasConversationOrTicketAttribute() {
    return this._allFilterItems.some((group) => group.items.length > 0);
  }

  get canShowMoreFilters() {
    return this.hasConversationOrTicketAttribute;
  }

  @cached
  get predicateGroup() {
    return PredicateGroup.from(this.args.filters ?? '');
  }

  @cached
  get teammatePredicate() {
    return this.predicateGroup.findPredicate<SimplePredicate>('teammate');
  }

  @cached
  get teamPredicate() {
    return this.predicateGroup.findPredicate<NestedPredicate>('team');
  }

  @cached
  get createdByPredicate(): Omit<SimplePredicate, 'identifier'> | undefined {
    let cvdaPredicates = this.cvdaPredicate?.predicates;

    if (cvdaPredicates && cvdaPredicates.length > 0) {
      return cvdaPredicates.find(
        (predicate) => predicate.attribute === this.CREATED_BY_TEAMMATE_ATTRUBUTE_IDENTIFIER,
      );
    }
    return;
  }

  @cached
  get tagsPredicate() {
    return this.predicateGroup.findPredicate<NestedPredicate>('tags');
  }

  @cached
  get userPredicate() {
    return this.predicateGroup.findPredicate<SimplePredicate>('user');
  }

  @cached
  get companyPredicate() {
    return this.predicateGroup.findPredicate<SimplePredicate>('company');
  }

  @cached
  get dateRangeStartPredicate() {
    return this.predicateGroup.findPredicate<DateRangePredicate>('date-range-start');
  }

  @cached
  get dateRangeEndPredicate() {
    return this.predicateGroup.findPredicate<DateRangePredicate>('date-range-end');
  }

  @cached
  get statePredicate() {
    return this.predicateGroup.findPredicate<SimplePredicate>('state');
  }

  @cached
  get priorityPredicate() {
    return this.predicateGroup.findPredicate<SimplePredicate>('priority');
  }

  @cached
  get conversationTypePredicate() {
    return this.predicateGroup.findPredicate<SimplePredicate>('conversation-type');
  }

  @cached
  get ticketStatePredicate() {
    return this.predicateGroup.findPredicate<TicketStatePredicate>('ticket-state');
  }

  @cached
  get topicPredicate() {
    return this.predicateGroup.findPredicate<NestedPredicate>('conversation-topic');
  }

  @cached get cvdaPredicate() {
    return this.predicateGroup.findPredicate<NestedPredicate>('conversation-attribute');
  }

  get sortOptions(): readonly SortOption[] {
    return Object.freeze([
      this.relevanceSortOption,
      {
        id: InboxSearchSort.Newest,
        text: this.intl.t(`inbox.conversation-filters.sort-by.newest`),
        sortState: { sortField: 'sorting_updated_at', direction: 'desc' },
      },
      {
        id: InboxSearchSort.Oldest,
        text: this.intl.t(`inbox.conversation-filters.sort-by.oldest`),
        sortState: { sortField: 'sorting_updated_at', direction: 'asc' },
      },
    ]);
  }

  get relevanceSortOption(): SortOption {
    return {
      id: InboxSearchSort.Relevance,
      text: this.intl.t(`inbox.conversation-filters.sort-by.relevance`),
      sortState: { sortField: 'relevance', direction: 'desc' },
    };
  }

  get teammateAssigned() {
    return this.inbox2AssigneeSearch.searchableAdmins.find(
      (admin) => admin.data.id.toString() === this.teammatePredicate?.value,
    )?.data as AdminWithPermissions | undefined;
  }

  get teamsAssigned() {
    let teams = new Set(this.teamPredicate?.predicates.map((predicate) => predicate.value));

    return (this.inbox2AssigneeSearch.searchableTeams
      .filter((team) => teams.has(team.data.id.toString()))
      ?.map((row) => row.data) ?? []) as TeamSummary[];
  }

  get dateRange() {
    if (this.dateRangeStartPredicate && this.dateRangeEndPredicate) {
      return {
        start: fromAbsoluteDate(this.dateRangeStartPredicate.value),
        end: fromAbsoluteDate(this.dateRangeEndPredicate.value),
      };
    } else {
      return undefined;
    }
  }

  get tags() {
    if (!this.tagsPredicate) {
      return [];
    }

    let ids = new Set(this.tagsPredicate.predicates.map((predicate) => predicate.value));
    return (this.inbox2TagsSearch.searchableTags || [])
      .filter((tag) => ids.has(tag.data.id.toString()))
      .map((tag) => tag.data);
  }

  get state() {
    return intToState(this.statePredicate?.value);
  }

  get priority() {
    return this.priorityPredicate?.comparison as BooleanComparison;
  }

  get ticketState() {
    return this.ticketStatePredicate?.value;
  }

  get hasParams(): boolean {
    return !isEmpty(this.args.query?.trim()) || !this.predicateGroup.isEmpty;
  }

  get conversationType() {
    let comparison = this.conversationTypePredicate?.comparison;
    if (comparison === 'unknown') {
      return InboxConversationTypeOption.Conversation;
    } else if (comparison === 'known') {
      return InboxConversationTypeOption.Ticket;
    }
    return InboxConversationTypeOption.All;
  }

  @use topicsLoader = AsyncData<ConversationTopic[]>(async ({ signal }) => {
    let topics = await this.inbox2ConversationalInsightsApi.fetchAllTopics({ signal });
    return topics;
  });

  @restartableTask *onQueryChanged(value: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'search_initiated',
      object: 'conversations',
      query_keyword: value,
      section: 'search',
      predicates: JSON.stringify(this.predicateGroup),
    });

    yield timeout(ENV.APP._350MS);

    this.transitionToSearchHome({ query: value });
  }

  @action clearFilters() {
    this.clearMoreFilters();
    this.transitionToSearchHome({ filters: undefined, sort: undefined, query: undefined });
  }

  @action transitionToSearchHome(queryParams?: Record<string, string | undefined>) {
    if (queryParams) {
      this.router.transitionTo('inbox.workspace.inbox.search', {
        queryParams,
      });
    } else {
      this.router.transitionTo('inbox.workspace.inbox.search');
    }
  }

  @action selectTeammate(admin: AdminSummary | undefined) {
    let adminId = convertStringIdToNumber(this.teammatePredicate?.value as string);
    let adminAlreadyAssigned = adminId === admin?.id;

    if (!admin || adminAlreadyAssigned) {
      this.predicateGroup.remove('teammate');
    } else if (this.teammatePredicate) {
      this.teammatePredicate.value = admin.id.toString();
    } else {
      this.predicateGroup.add({
        identifier: 'teammate',
        attribute: 'conversation.admin_assignee_id',
        comparison: 'eq',
        type: 'admin_assignee_id',
        value: admin.id.toString(),
      });
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectTeam(team: TeamSummary | null) {
    if (!team) {
      this.predicateGroup.remove('team');
      this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });

      return;
    }

    let predicate: NestedPredicate['predicates'][number] = {
      attribute: 'conversation.team_assignee_id',
      comparison: 'eq',
      type: 'team_assignee_id',
      value: team.id.toString(),
    };

    if (this.teamPredicate) {
      if (this.teamsAssigned?.any((t) => team.id === t.id)) {
        this.teamPredicate.predicates = this.teamPredicate.predicates.filter(
          (predicate) => predicate.value !== team.id.toString(),
        );

        if (this.teamPredicate.predicates.length === 0) {
          this.predicateGroup.remove('team');
        }
      } else {
        this.teamPredicate.predicates.pushObject(predicate);
      }
    } else {
      this.predicateGroup.add({
        identifier: 'team',
        type: 'or',
        predicates: [predicate],
      });
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectTag(tag: Tag) {
    let predicate: NestedPredicate['predicates'][number] = {
      attribute: 'conversation.tag_ids',
      comparison: 'in',
      type: 'manual_tag',
      value: tag.id.toString(),
    };

    if (this.tagsPredicate) {
      if (this.tags.any((t) => tag.id === t.id)) {
        this.tagsPredicate.predicates = this.tagsPredicate.predicates.filter(
          (predicate) => predicate.value !== tag.id.toString(),
        );

        if (this.tagsPredicate.predicates.length === 0) {
          this.clearTags();
        }
      } else {
        this.tagsPredicate.predicates.pushObject(predicate);
      }
    } else {
      this.predicateGroup.add({
        identifier: 'tags',
        type: 'and',
        predicates: [predicate],
      });
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action clearTags() {
    this.predicateGroup.remove('tags');
    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectTopic(topic: ConversationTopic) {
    let predicate: NestedPredicate['predicates'][number] = {
      attribute: 'conversation.topics',
      comparison: 'in',
      type: 'conversation_topic',
      value: topic.id.toString(),
    };

    if (this.topicPredicate) {
      if (this.topics.any((t) => topic.id === t.id)) {
        this.topicPredicate.predicates = this.topicPredicate.predicates.filter(
          (predicate) => predicate.value !== topic.id.toString(),
        );

        if (this.topicPredicate.predicates.length === 0) {
          this.clearTopics();
        }
      } else {
        this.topicPredicate.predicates.pushObject(predicate);
      }
    } else {
      this.predicateGroup.add({
        identifier: 'conversation-topic',
        type: 'and',
        predicates: [predicate],
      });
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action clearTopics() {
    this.predicateGroup.remove('conversation-topic');
    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectUser(user: UserSummary | null) {
    let userAlreadyAssigned = this.userPredicate?.value === user?.id;

    if (!user || userAlreadyAssigned) {
      this.predicateGroup.remove('user');
    } else if (this.userPredicate) {
      this.userPredicate.value = user.id.toString();
    } else {
      this.predicateGroup.add({
        identifier: 'user',
        attribute: 'user_ids',
        comparison: 'eq',
        type: 'id',
        value: user.id.toString(),
      });
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectCompany(company: CompanySummary | null) {
    let toggleSelectedCompany = this.companyPredicate?.value === company?.id;

    if (!company || toggleSelectedCompany) {
      this.predicateGroup.remove('company');
    } else if (this.companyPredicate) {
      this.companyPredicate.value = company.id.toString();
    } else {
      this.predicateGroup.add({
        identifier: 'company',
        attribute: 'associated_company_ids',
        comparison: 'eq',
        type: 'id',
        value: company.id.toString(),
      });
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectDate(start: moment.Moment | null, end: moment.Moment | null) {
    let isSameDateAlreadySelected =
      start &&
      isEqual(this.dateRangeStartPredicate?.value, toAbsoluteDate(start)) &&
      end &&
      isEqual(this.dateRangeEndPredicate?.value, toAbsoluteDate(end));

    if (!start || !end || isSameDateAlreadySelected) {
      this.predicateGroup.remove('date-range-start');
      this.predicateGroup.remove('date-range-end');
    } else if (this.dateRangeStartPredicate && this.dateRangeEndPredicate) {
      this.dateRangeStartPredicate.value = toAbsoluteDate(start);
      this.dateRangeEndPredicate.value = toAbsoluteDate(end);
    } else {
      this.predicateGroup.add({
        identifier: 'date-range-start',
        attribute: 'conversation_started_at',
        comparison: 'gt',
        type: 'date',
        value: toAbsoluteDate(start),
      });

      this.predicateGroup.add({
        identifier: 'date-range-end',
        attribute: 'conversation_started_at',
        comparison: 'lt',
        type: 'date',
        value: toAbsoluteDate(end),
      });
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectConversationType({ id }: { id: InboxConversationTypeOption }) {
    this.predicateGroup.remove('conversation-type');

    if (id === InboxConversationTypeOption.Ticket) {
      this.predicateGroup.add({
        identifier: 'conversation-type',
        attribute: 'conversation.ticket_type_id',
        comparison: 'known',
        type: 'integer',
        value: null,
      });
    } else if (id === InboxConversationTypeOption.Conversation) {
      this.predicateGroup.add({
        identifier: 'conversation-type',
        attribute: 'conversation.ticket_type_id',
        comparison: 'unknown',
        type: 'integer',
        value: null,
      });
    }
    return this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectState(state: InboxStateOption | null) {
    let isSameStateAlreadySelected =
      this.ticketStatePredicate?.value === state ||
      intToState(this.statePredicate?.value) === state;

    if (!state || isSameStateAlreadySelected) {
      this.predicateGroup.remove('state');
      this.predicateGroup.remove('ticket-state');
      return this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
    }

    if (isTicketState(state)) {
      let ticketState = state as TicketSystemState;
      this.predicateGroup.remove('state');
      if (this.ticketStatePredicate) {
        this.ticketStatePredicate.value = ticketState;
      } else {
        this.predicateGroup.add({
          identifier: 'ticket-state',
          attribute: 'conversation.ticket_state',
          comparison: 'eq',
          type: 'string',
          value: ticketState,
        });
      }
    } else {
      this.predicateGroup.remove('ticket-state');
      let enumValue = stateToInt(state);
      if (enumValue === undefined) {
        throw new Error(`Setting invalid state ${enumValue}`);
      }

      if (this.statePredicate) {
        this.statePredicate.value = enumValue;
      } else {
        this.predicateGroup.add({
          identifier: 'state',
          attribute: 'conversation.state',
          comparison: 'eq',
          type: 'integer',
          value: enumValue,
        });
      }
    }

    this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectPriority(priority: BooleanComparison) {
    this.predicateGroup.remove('priority');

    if (priority !== undefined) {
      this.predicateGroup.add({
        identifier: 'priority',
        type: 'priority',
        attribute: 'conversation.priority',
        comparison: priority,
        value: null,
      });
    }

    return this.transitionToSearchHome({ filters: this.predicateGroup.toB64() });
  }

  @action selectSort(sort: Search2Component['sortOptions'][number]) {
    this.activeSortOption =
      this.sortOptions.find((option) => option.id === sort.id) || this.relevanceSortOption;
    this.saveSortSettings();
    this.router.transitionTo({
      queryParams: { sort: sort.id },
    });
  }

  get settings() {
    return this.conversationsTableSettings.getSearchSettings(true);
  }

  saveSortSettings(sortOption?: SortOption) {
    this.conversationsTableSettings.saveSearchSettings(
      {
        ...this.settings,
        selectedSortOption: sortOption?.sortState || this.activeSortOption?.sortState,
      },
      true,
    );
  }

  saveColumnSettings(selectedColumns: SelectedColumn[]) {
    this.conversationsTableSettings.saveSearchSettings(
      {
        ...this.settings,
        selectedColumns,
      },
      true,
    );
  }

  @action clearSearch() {
    this.clearMoreFilters();
    this.router.transitionTo('inbox.workspace.inbox.search', {
      queryParams: { filters: null, query: null },
    });
  }

  @action onTableSortChanged(column: ConversationsTableColumn, direction: SortDirection) {
    let option = this.sortOptions.find(
      (option) =>
        option.sortState.sortField === column.sortField && option.sortState.direction === direction,
    );

    if (!option) {
      return;
    }
    this.activeSortOption = option;
    this.saveSortSettings();

    this.router.transitionTo({
      queryParams: { sort: option.id },
    });
  }

  @use user = AsyncData<UserSummary | undefined>(async ({ signal }) => {
    if (!this.userPredicate) {
      return;
    }

    let user = await this.userApi.fetchUser(this.userPredicate.value as string, { signal });
    return user;
  });

  @use company = AsyncData<CompanySummary | undefined>(async () => {
    if (!this.companyPredicate) {
      return;
    }

    let company = await this.companyApi.fetchCompany(this.companyPredicate.value as string);
    return company;
  });

  get topics() {
    if (!this.topicPredicate) {
      return [];
    }
    let ids = new Set(this.topicPredicate.predicates.map((predicate) => predicate.value));

    return (this.topicsLoader.value ?? []).filter((topic) => ids.has(topic.id.toString()));
  }

  get isSearchTextEmpty(): boolean {
    return !this.args.query || this.args.query === '';
  }

  get hasNoSearchablePredicates(): boolean {
    return (
      this.predicateGroup.isEmpty ||
      (this.predicateGroup.predicates.length === 1 && this.conversationTypePredicate !== undefined)
    );
  }

  get isSearchCriteriaEmpty(): boolean {
    return this.isSearchTextEmpty && this.hasNoSearchablePredicates;
  }

  get fields() {
    if (!this.tableColumns) {
      return [];
    }

    return [
      ...REQUIRED_CONVERSATIONS_TABLE_FIELDS,
      ...this.tableColumns.columns.flatMap((column) => column.fields),
    ].uniq();
  }

  get hiddenColumns() {
    return this.tableColumns?.hiddenColumns ?? [];
  }

  get columns() {
    return this.tableColumns?.columns ?? [];
  }

  @task({ drop: true }) *loadDynamicColumns(owner: unknown): TaskGenerator<void> {
    let [descriptors, ticketTypes] = yield Promise.all([
      this.session.workspace.fetchConversationAttributeDescriptors(),
      this.inboxApi.listTicketTypes(),
    ]);

    this.descriptors = descriptors;
    this.ticketTypes = ticketTypes;

    this.tableColumns = new ConversationsTableColumns(
      owner,
      this.settings.selectedColumns,
      new ColumnDefinitionsBuilder(
        owner,
        this.ticketTypes,
        this.descriptors,
        'search',
      ).columnDefinitions,
      this.ticketTypes,
    );
  }

  @action changeColumns(columns: ColumnDefinition[]) {
    let selectedColumns = columns.map((col) => ({
      id: columnId(col),
      width: col.width,
    }));
    this.tableColumns!.selectedColumns = selectedColumns;
    this.saveColumnSettings(selectedColumns);
  }

  @action onSortColumn(column: ConversationsTableColumn, direction: SortDirection) {
    if (!column.sortField) {
      return;
    }

    this.inboxState.selectedConversations.clear();
    this.onTableSortChanged(column, direction);
  }

  @action onPreviewConversation(
    resource: ConversationsSearchResource,
    conversation: ConversationTableEntry,
  ) {
    if (conversation.relevantPart) {
      let permalink = `part_id=${conversation.relevantPart.generatePermalinkId(conversation.id)}`;
      this.inboxRedirectionService.setConversationPartAnchor(permalink);
    }

    this.router.transitionTo(
      'inbox.workspace.inbox.search.conversation.conversation',
      conversation,
    );

    let conversations = resource.conversations;
    let position = conversations.findIndex(({ id }) => id === conversation.id) + 1;
    let searchUUID = resource.searchUUID;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversation',
      section: 'conversation_table',
      conversation_id: conversation.id,
      search_result_position: position,
      search_uuid: searchUUID,
    });
  }

  @action onClosePreview() {
    this.router.transitionTo('inbox.workspace.inbox.search', {
      queryParams: { skipRedirection: true },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search2': typeof Search2Component;
  }
}
