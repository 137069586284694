/* import __COLOCATED_TEMPLATE__ from './sort-by.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import type Session from 'embercom/services/session';
import { inject as service } from '@ember/service';
import {
  ColumnDefinitionsBuilder,
  type ColumnGroup,
  ConversationsTableColumns,
} from 'embercom/objects/inbox/conversations-table-column';
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import ConversationListSortByItemComponent from 'embercom/components/inbox2/left-nav/conversation-list/sort-by-item';
import { type SortField, type SortDirection, type SortParams } from 'embercom/services/inbox-api';
import { next } from '@ember/runloop';

interface Args {
  selectedSortOption: SortParams;
  onSortOptionChanged: (newSort: SortParams) => void;
}

interface Signature {
  Args: Args;
}

export default class ConversationListSortByComponent extends Component<Signature> {
  @service declare session: Session;

  get descriptors() {
    return this.session.workspace.conversationDataAttributeDescriptors.value || [];
  }

  get isLoadingData() {
    return (
      this.session.workspace.conversationDataAttributeDescriptors.isLoading ||
      this.session.workspace.ticketTypes.isLoading
    );
  }

  get ticketTypes() {
    return this.session.workspace.ticketTypes.value || [];
  }

  get selectedColumnDefinition() {
    return this.columnDefinitions.find(
      (column) => column.sortField === this.args.selectedSortOption.sort_field,
    );
  }

  get columnDefinitions() {
    let builder = new ColumnDefinitionsBuilder(
      getOwner(this),
      this.ticketTypes,
      this.descriptors,
      'inbox',
    );
    return builder.columnDefinitions;
  }

  get columns() {
    let builder = new ConversationsTableColumns(
      getOwner(this),
      [],
      this.columnDefinitions,
      this.ticketTypes,
    );
    return builder.hiddenColumns;
  }

  get selectItems() {
    return this.columns.map((columnGroup: ColumnGroup) => {
      return {
        heading: columnGroup.heading,
        items: columnGroup.columns
          .filter((column) => column.isSortable)
          .map((column) => {
            return {
              text: column.label,
              icon: column.icon,
              value: column.sortField,
              component: ConversationListSortByItemComponent,
              componentAttrs: {
                isSelected: this.args.selectedSortOption.sort_field === column.sortField,
                direction: this.args.selectedSortOption.sort_direction,
              },
            };
          }),
      };
    });
  }

  @action checkValidity() {
    if (this.isLoadingData) {
      return;
    }

    // if nothing is selected we must have invalid data, reset to defaults
    if (!this.selectedColumnDefinition) {
      next(this, () => {
        this.args.onSortOptionChanged({
          sort_field: 'sorting_updated_at',
          sort_direction: 'desc',
        });
      });
    }
  }

  // Implements side effects for resources as per
  // https://github.com/NullVoxPopuli/ember-resources/issues/988
  effect = (fn: (...args: unknown[]) => void) => {
    fn();
  };

  @action onChange(selected: SortField) {
    let newDirection: SortDirection;
    if (this.args.selectedSortOption.sort_field === selected) {
      newDirection = this.args.selectedSortOption.sort_direction === 'asc' ? 'desc' : 'asc';
    } else {
      newDirection = 'asc';
    }
    this.args.onSortOptionChanged({
      sort_field: selected,
      sort_direction: newDirection,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationList::SortBy': typeof ConversationListSortByComponent;
    'inbox2/left-nav/conversation-list/sort-by': typeof ConversationListSortByComponent;
  }
}
