/* import __COLOCATED_TEMPLATE__ from './customer-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import UserSummary from 'embercom/objects/inbox/user-summary';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';
import type ClipboardService from 'embercom/services/clipboard-service';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';

interface Args {
  selectItem: (user: UserSummary) => void;
  hideDropdownOverlay?: () => void;
  user?: UserSummary;
  email?: string;
  isNewCustomer?: boolean;
}

interface Signature {
  Args: Args;
}

export default class CustomerDropdownItem extends Component<Signature> {
  @service declare router: RouterService;
  @service declare clipboardService: ClipboardService;
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare intl: IntlService;
  @service declare session: Session;

  clipboard?: any;

  @action
  selectCustomer(event: MouseEvent) {
    let isUserIdTooltip =
      event.target instanceof Element && event.target.closest('[data-is-user-id-tooltip]') !== null;
    if (isUserIdTooltip) {
      event.preventDefault();
      return;
    }

    if (!this.args.user) {
      return;
    }

    this.args.selectItem(this.args.user);
    this.args.hideDropdownOverlay?.();
  }

  @action
  addNewCustomer() {
    let user = new UserSummary('', this.args.email);
    this.args.selectItem(user);
  }

  @action openUserProfile(workspaceId: string, userId: string) {
    let url = this.router.urlFor('apps.app.users.user', workspaceId, userId);
    safeWindowOpen(url, '_blank');
  }

  @action copyUserId(userId: string) {
    if (!this.clipboard) {
      this.clipboard = this.clipboardService.createClipboard(
        '#copy-user-id-button',
        userId,
        () => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('inbox.new-conversation.recipient-selector-tooltip.user-id-copy-success'),
          );
        },
        () => {
          this.notificationsService.notifyWarning(
            this.intl.t('inbox.new-conversation.recipient-selector-tooltip.user-id-copy-error'),
          );
        },
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::CustomerDropdownItem': typeof CustomerDropdownItem;
    'inbox2/ticket-attribute/customer-dropdown-item': typeof CustomerDropdownItem;
  }
}
