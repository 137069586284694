/* import __COLOCATED_TEMPLATE__ from './file-upload-pill-container.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import { type Upload } from '../file';
import { type TicketDescriptor } from 'embercom/objects/inbox/ticket';
import type Session from 'embercom/services/session';

interface Args {
  uploads: Upload[];
  onDelete: (toDelete: Upload) => void;
  descriptor: TicketDescriptor;
  onUpdateValue: (value: unknown) => unknown;
  isReadOnly: boolean;
}

interface Signature {
  Args: Args;
}

export default class FileUploadPillContainer extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;

  @tracked uploadsByDescriptorId: Record<number, Upload[]> = {};

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.uploadsByDescriptorId[this.args.descriptor.id] = [...this.args.uploads];
  }

  get uploads() {
    return this.uploadsByDescriptorId[this.args.descriptor.id] || [];
  }

  set uploads(value: Upload[]) {
    this.uploadsByDescriptorId = {
      ...this.uploadsByDescriptorId,
      [this.args.descriptor.id]: [...value],
    };
  }

  onUploadProgress(event: ProgressEvent, uploadId: number | string) {
    let progressPercent = Math.round((event.loaded / event.total) * 100);
    this.uploads = [
      ...this.uploads.map((upload: Upload) => {
        if (upload.id === uploadId) {
          if (progressPercent > upload.progress) {
            upload.progress = progressPercent - 1;
          }
        }
        return { ...upload };
      }),
    ];
  }

  @action
  onDeleteClicked(indexToDelete: number) {
    let fileToDelete = this.uploads.filter((_, index: number) => {
      return index === indexToDelete;
    })[0];

    this.args.onDelete(fileToDelete);
    this.uploads = this.uploads.filter((_, index: number) => {
      return index !== indexToDelete;
    });
  }

  @action
  async fileChanged(uploads: Array<File> | File) {
    let files = Array.isArray(uploads) ? uploads : [uploads];
    for (let file of files) {
      let tempId = `tmp-${Math.round(Math.random() * 10000)}`;

      this.uploads.push({
        id: tempId,
        name: file.name,
        progress: 0,
        contentType: file.type,
      });

      let uploader = new EmbercomFileUploader(
        file,
        {
          policyUrl: `/apps/${this.session.workspace.id}/uploads`,
        },
        (e) => this.onUploadProgress(e, tempId),
      );
      let response = await uploader.upload();
      if (response.type === 'failure') {
        this.snackbar.notifyError(response.reason);
        return;
      }

      this.uploads = this.uploads.map((upload: Upload) => {
        if (upload.id === tempId) {
          upload.id = uploader.policy.id;
          upload.url = uploader.policy.public_url;
          upload.progress = 100;
        }
        return { ...upload };
      });

      this.uploadAttributeValue();
    }
  }

  get allowedFileTypes() {
    return ['*'];
  }

  get canAddFiles() {
    return (
      this.uploads.length < (this.args.descriptor.allowMultipleValues ? 10 : 1) &&
      !this.args.isReadOnly
    );
  }

  uploadAttributeValue() {
    this.args.onUpdateValue(
      this.uploads.filter((upload: Upload) => {
        return !/tmp-/.test(`${upload.id}`);
      }),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::FileAttribute::FileUploadPillContainer': typeof FileUploadPillContainer;
    'inbox2/ticket-attribute/file-attribute/file-upload-pill-container': typeof FileUploadPillContainer;
  }
}
