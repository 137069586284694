/* import __COLOCATED_TEMPLATE__ from './user-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';
import copyToClipboard from 'copy-to-clipboard';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';

import type UserSummary from 'embercom/objects/inbox/user-summary';

interface Signature {
  Args: { user: UserSummary | undefined; onSelect: (user: UserSummary) => any };
}

export default class UserSelector extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare session: Session;
  @service declare notificationsService: InstanceType<typeof NotificationsService>;

  @action openUserProfile(workspaceId: string, userId: string, event: MouseEvent) {
    event.stopPropagation();
    let url = this.router.urlFor('apps.app.users.user', workspaceId, userId);
    safeWindowOpen(url, '_blank');
  }

  @action copyUserId(userId: string, event: MouseEvent) {
    event.stopPropagation();
    copyToClipboard(userId, {
      format: 'text/plain',
      onCopy: () => {
        this.notificationsService.notifyConfirmation(
          this.intl.t('inbox.new-conversation.recipient-selector-tooltip.user-id-copy-success'),
        );
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Search::UserSelector': typeof UserSelector;
  }
}
